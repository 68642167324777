import * as React from "react";
import {useEffect} from "react";
import {createTheme, styled, ThemeProvider} from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {useDispatch, useSelector} from 'react-redux';
import reduxApis from "redux/apis";
import imageUrl from "utils/imageUrl";
import DashboardChart from "components/DashboardChart";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import {Header} from "components";
import useTranslation from "hooks/useTranslation";
import {Alert} from "@mui/material";
import {TOTAL_SHARES} from "../../assets/constants";
import _ from "lodash"

const mdTheme = createTheme();


export default function Dashboard() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(true);
    const {t} = useTranslation();


    const toggleDrawer = () => setOpen(!open);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const {user} = useSelector((store) => store.user);
    const {wallet} = useSelector((store) => store.wallet);

    useEffect(() => {
        (async () => {
            const res = await dispatch(reduxApis.getWallet(user.id))
            // console.log(res);
        })();
    }, []);

    const dispatch = useDispatch();

    const popOpen = Boolean(anchorEl);
    const id = popOpen ? "simple-popover" : undefined;

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header/>
                        <Container maxWidth="lg" sx={{mt: 0, mb: 4}}>
                            <h3 className="dashboard-title">{t("titles.dashboard")}</h3>
                            {wallet?.shares > 0 && (
                                <div className="pt-2">
                                    <Alert severity="info">You are {_.round((wallet?.shares * 100) / TOTAL_SHARES, 5)}%
                                        of Participate of Java Times fundraiser</Alert>
                                </div>
                            )}
                            <Grid container className="mt-4">
                                {/*small boxes*/}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.dashboard_1")}</h3>
                                            <h4 className="">{wallet?.amount}</h4>
                                        </div>
                                        <img src={imageUrl('d_available_shares.png')}/>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.dashboard_2")}</h3>
                                            <h4 className="">{wallet?.shares}</h4>
                                        </div>
                                        <img src={imageUrl('d_shares.png')}/>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Paper
                                        className="dashboard-box"
                                    >
                                        <div className="text">
                                            <h3 className="">{t("labels.dashboard_3")}</h3>
                                            <h4 className="">{wallet?.dividend}</h4>
                                        </div>
                                        <img src={imageUrl('d_profit.png')}/>
                                    </Paper>
                                </Grid>
                                {/*Chart*/}
                                <Grid item xs={12} md={12} lg={12}>
                                    <Paper
                                        sx={{
                                            p: 2,
                                            display: "flex",
                                            flexDirection: "column",
                                            height: '100%',
                                        }}
                                    >
                                        <DashboardChart/>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </Box>
        </ThemeProvider>
    );
}


const drawerWidth = 240;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({theme, open}) => ({
    "& .MuiDrawer-paper": {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        backgroundColor: "#E21C21",
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        }),
    },
}));
