import { useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { USERS_TYPES } from "../../assets/constants";
import useTranslation from "hooks/useTranslation";

const InvestOptionsModal = ({ status, toggleModal }) => {
  const navigate = useNavigate();
  const {  t } = useTranslation();

  const investorHandler = () =>
    navigate(`/investment-registration?type=investor`);
  const legalEntityInvestor = () =>
    navigate("/investment-registration?type=legalEntity");

  return (
    <Modal
      show={status}
      onHide={toggleModal}
      centered
      className="invest-options-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>{t("titles.invest_options_modal")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>{t("labels.invest_options_modal")}</Col>
          <Col xs={12} className="d-flex justify-content-around my-2 gap-2">
            <Button variant="outline-primary" onClick={investorHandler}>
              {t("buttons.investor")}
            </Button>
            <Button variant="outline-danger" onClick={legalEntityInvestor}>
              {t("buttons.legal_entity_investor")}
            </Button>
            <Button variant="outline-dark" onClick={toggleModal} disabled>
              {t("buttons.jtc_employee_investor")}
            </Button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default InvestOptionsModal;
