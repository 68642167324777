import apis from "services";
import {toast} from "react-toastify";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Col, Form, Row} from "react-bootstrap";
import {InputAdornment} from "@mui/material";

import "react-toastify/dist/ReactToastify.css";

import CustomTextField from "../ui/CustomTextField";
import {AccountBalance, Person,} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {setUserType} from "../../redux/slices/userSlice";
import useTranslation from "hooks/useTranslation";

const textFieldStyle = {color: "var(--primary-red)"};

const InvestorForm = ({isCreatedByAdmin}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {t} = useTranslation();


    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({hasFundsLegalSource: false, RFCType: 'Number', CURPType: 'Number'});

    const {user} = useSelector((store) => store.user);

    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setState(prevState => ({...prevState, [id]: value}))
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        const data = new FormData(e.currentTarget)
        const formData = Object.fromEntries(data);

        console.log(formData)

        try {
            setLoading(true);
            const response = await apis.investorRegistration({...formData, userId: user.id})
            if (response.status === 201) {
                const {message = 'Successfully Registered'} = response.data
                dispatch(setUserType("investor"))
                toast.success(message);
            }

            setTimeout(() => isCreatedByAdmin ? navigate(-1) : navigate("/invest"), 2000);
        } catch (error) {
            setLoading(false);
            console.log('Error occurred while investor registration', error);
            toast.error(error.message);
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit} enctype="multipart/form-data">
                <Row>
                    {/* <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="name"
                            label="Name"
                            name='name'
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col> */}
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="firstName"
                            name="firstName"
                            label={t("labels.first_name")}
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="middleName"
                            name="middleName"
                            label={t("labels.middle_name")}
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="lastName"
                            name="lastName"
                            label={t("labels.last_name")}
                            variant="standard"
                            // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                    {/* <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="phone"
                            name="phone"
                            label="Phone Number"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PhoneInTalk sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col> */}
                </Row>

                <Row>
                    {/* <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="fatherName"
                            name="fatherName"
                            label="Father Name"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="motherName"
                            name="motherName"
                            label="Mother Name"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col> */}
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="bankAccount"
                            name="bankAccount"
                            label={t("labels.bank_account")}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountBalance sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                            // onChange={onChangeHandler}
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="passport"
                            name="passport"
                            label={t("labels.passport")}
                            variant="standard"
                            fullWidth
                            required
                            // onChange={onChangeHandler}
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="passportExpireDate"
                            name="passportExpireDate"
                            type="date"
                            variant="standard"
                            label={t("labels.passport_expire_date")}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                            // onChange={onChangeHandler}
                        />
                    </Col>
                    {/*<Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="email"
                            name="email"
                            label="Email Address"
                            type="email"
                            variant="standard"
                           // onChange={onChangeHandler}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>*/}
                </Row>


                {/*<Row >
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="password"
                            name="password"
                            label="Password"
                            type={togglePassword.password ? "text" : "password"}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton size="small" onClick={() => toggle("password")}>
                                            {togglePassword.password ? (
                                                <VisibilityOff sx={textFieldStyle}/>
                                            ) : (
                                                <Visibility sx={textFieldStyle}/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                           // onChange={onChangeHandler}
                            required
                        />
                    </Col>
                    <Col md={6} lg={4} className="my-3">
                        <CustomTextField
                            id="confirmPassword"
                            name="confirmPassword"
                            label="Password Confirmation"
                            type={togglePassword.confirmPassword ? "text" : "password"}
                           // onChange={onChangeHandler}
                            variant="standard"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Person sx={textFieldStyle}/>
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            size="small"
                                            onClick={() => toggle("confirmPassword")}
                                        >
                                            {togglePassword.confirmPassword ? (
                                                <Visibility sx={textFieldStyle}/>
                                            ) : (
                                                <VisibilityOff sx={textFieldStyle}/>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                            required
                        />
                    </Col>
                </Row>*/}

                <Row>
                    <Col md={12} className="my-3">
                        <Form.Group className="mb-3">
                            <Form.Check
                                type="checkbox"
                                label={t("labels.letter_of_funds")}
                                checked={state.hasFundsLegalSource}
                                onChange={(e) =>
                                    setState((prevState) => ({
                                        ...prevState,
                                        hasFundsLegalSource: e.target.checked,
                                    }))
                                }
                            />
                        </Form.Group>
                        {state.hasFundsLegalSource && (
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="file"
                                        id="fundsLegalSource"
                                        name="fundsLegalSource"
                                        // onChange={onChangeHandler}
                                        required
                                    />
                                    <Form.Text className="text-muted">
                                        {t("labels.letter_of_source")}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        )}
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                className="mb-3"
                                inline
                                label={t("labels.rfc_no")}
                                value="Number"
                                type="radio"
                                id="RFCType"
                                onChange={onChangeHandler}
                                checked={state.RFCType === "Number"}
                            />
                            <Form.Check
                                className="mb-3"
                                inline
                                label={t("labels.rfc_doc")}
                                value="Document"
                                type="radio"
                                id="RFCType"
                                onChange={onChangeHandler}
                                checked={state.RFCType === "Document"}
                            />
                            {state.RFCType === "Document" ? (
                                <Form.Control
                                    type="file"
                                    id="RFC"
                                    name="RFC"
                                    // onChange={onChangeHandler}
                                    required
                                />
                            ) : (
                                <CustomTextField
                                    id="RFC"
                                    name="RFC"
                                    type={state.RFCType === "Number" ? "text" : "file"}
                                    variant="standard"
                                    // onChange={onChangeHandler}
                                    required
                                    fullWidth
                                />
                            )}
                            {/* <Form.Control
                type="file"
                id="RFC"
                // // onChange={onChangeHandler}
               // onChange={onChangeHandler}
                name="RFC"
                required
              /> */}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Check
                                className="mb-3"
                                inline
                                label={t("labels.curp_no")}
                                value="Number"
                                name="CURPType"
                                type="radio"
                                id="CURPType"
                                onChange={onChangeHandler}
                                checked={state.CURPType === "Number"}
                            />
                            <Form.Check
                                className="mb-2"
                                inline
                                label={t("labels.curp_doc")}
                                value="Document"
                                name="CURPType"
                                type="radio"
                                id="CURPType"
                                onChange={onChangeHandler}
                                checked={state.CURPType === "Document"}
                            />
                            {state.CURPType === "Document" ? (
                                <Form.Control
                                    type="file"
                                    id="CURP"
                                    name="CURP"
                                    // onChange={onChangeHandler}
                                    required
                                />
                            ) : (
                                <CustomTextField
                                    id="CURP"
                                    name="CURP"
                                    type={state.CURPType === "Number" ? "text" : "file"}
                                    variant="standard"
                                    // onChange={onChangeHandler}
                                    required
                                    fullWidth
                                />
                            )}
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={4}>

                        <button
                            className='lp-btn my-4 w-100'
                            // className="my-4"
                            variant="outlined"
                            color="error"
                            type="submit"
                            fullWidth
                            disabled={loading}
                        >
                            {loading ? t("buttons.submitting") : t("buttons.submit")}
                        </button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default InvestorForm;
