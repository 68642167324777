import React from "react";
import imageUrl from "utils/imageUrl";

const OpenPitch = ({ subTab }) => {
  return (
    <>
      <div className="open-pitch">
        {subTab === "problem" && (
          <>
            <section>
              <p className="section-title">Problem</p>
              <div className="section-img">
                <img src={imageUrl("prb.jpg")} alt="" />
              </div>
              <dl>
                <dt>Problem Java Times Caffe is solving</dt>
                <dd>
                  We have Build our business from ground up using Blockchain
                  technology has the potential to be used in a variety of ways
                  in the coffee shop industry, and there are a few specific ways
                  in which it could be used to create a successful coffee shop
                  solution
                </dd>
                <dt>Supply chain traceability</dt>
                <dd>
                  Blockchain technology could be used to create a transparent
                  and immutable record of the supply chain for coffee and other
                  products, allowing coffee shop customers to see exactly where
                  their coffee comes from and how it was produced. This could
                  help to build trust and confidence in the products and improve
                  the overall customer experience.
                </dd>
                <dt>Customer loyalty programs</dt>
                <dd>
                  A coffee shop could use blockchain technology to create a
                  customer loyalty program that rewards customers with JTC
                  tokens or other digital assets for making purchases. These JTC
                  tokens could then be used to redeem rewards or discounts, or
                  even traded with other customers.
                </dd>
                <dt>Digital payments</dt>
                <dd>
                  A coffee shop could use blockchain-based digital currencies or
                  other digital payment systems to allow customers to easily and
                  securely make payments for their purchases. This could be
                  especially useful for customers who prefer to pay with digital
                  currencies or for coffee shops that want to reduce their
                  reliance on cash transactions.
                </dd>
                <dd>
                  Overall, the use of blockchain technology in the coffee shop
                  industry is still in the early stages, and it is likely that
                  additional innovative uses will emerge as the technology
                  continues to mature and be adopted by more businesses.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab === "solution" && (
          <>
            <section>
              <p className="section-title">Solution</p>
              <div className="section-img">
                <img src={imageUrl("solution.png")} alt="" />
              </div>
              <dl>
                <dt>Java Times Caffe Best coffee shop solution</dt>
                <dd>
                  It is difficult to determine a single "best" coffee shop
                  solution, Thats why we have many concepts, as the needs and
                  preferences of coffee shops can vary widely depending on the
                  specific context and market they operate in. Factors that can
                  influence the success of a coffee shop solution include the
                  location, the target audience, the pricing and product
                  offering, the quality and consistency of the coffee and other
                  products, and the overall atmosphere and customer experience.
                </dd>
                <dt>
                  That being said, there are a few key elements that are
                  generally considered important for successful coffee shops,
                  regardless of their specific location or target audience.
                  These elements include:
                </dt>
                <dd>
                  High-quality coffee and other products: Successful coffee
                  shops typically offer high-quality coffee and other products
                  that are consistent in taste and appearance.
                </dd>
                <dd>
                  Convenient location and accessibility: Coffee shops that are
                  located in convenient, easily accessible locations tend to be
                  more successful, as they are more likely to attract a steady
                  stream of customers.
                </dd>
                <dd>
                  Comfortable, welcoming atmosphere: Customers are more likely
                  to return to a coffee shop if they feel comfortable and
                  welcome in the space.
                </dd>
                <dd>
                  Exceptional customer service: Excellent customer service can
                  be a key differentiator for coffee shops and can help to build
                  customer loyalty.
                </dd>
                <dd>
                  Ultimately, the "best" coffee shop solution will depend on the
                  specific needs and preferences of the business and its
                  customers.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab === "traction" && (
          <>
            <section>
              <p className="section-title">Traction</p>
              <div className="section-img">
                <img src={imageUrl("traction.png")} alt="" />
              </div>
              <dl>
                <dd>
                  Java Times Caffé is an exciting and innovative brand that
                  specializes in providing high-quality coffee, teas, and light
                  fare to its customers. Founded by industry expert Tony Leite
                  in 1999, the company has a proven track record of success,
                  with 22 locations That Operated operating in various cities
                  throughout Mexico under a franchising system at the time.
                </dd>
                <dd>
                  With a wealth of experience in the coffee and hospitality
                  industries, Tony Leite has developed a successful business
                  model that combines a passion for exceptional coffee and a
                  commitment to customer satisfaction. The company has a strong
                  focus on franchise system and now changing into a fractinal
                  investment platform, making it an ideal investment opportunity
                  for those looking to get in on the ground floor of a rapidly
                  growing business.
                </dd>
                <dd>
                  The company's prototype store is between 80 and 150 square
                  meters, with outdoor seating and a small kitchen that ranges
                  from 12 to 16 square meters. With a seating capacity of
                  between 30 to 70 people, each location is designed to provide
                  an inviting and comfortable atmosphere that encourages
                  customers to relax and enjoy their experience.
                </dd>
                <dd>
                  If you are looking for an opportunity to invest in a rapidly
                  growing brand that has a proven track record of success and a
                  passion for providing exceptional coffee, teas, and light
                  fare, then Java Times Caffé is the ideal choice.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab === "customers" && (
          <>
            <section>
              <p className="section-title">Customers</p>
              <div className="section-img">
                <img src={imageUrl("customers.png")} alt="" />
              </div>
              <dl>
                <dt>Coffee and tea customers in Mexico</dt>
                <dd>
                  Coffee and tea are popular beverages in Mexico, with a large
                  and diverse group of consumers. According to data from the
                  National Institute of Statistics and Geography (INEGI), around
                  90% of the Mexican population consumes coffee and/or tea on a
                  regular basis.
                </dd>
                <dd>
                  Coffee and tea customers in Mexico can vary in terms of their
                  preferences and habits. Some may prefer traditional Mexican
                  styles of coffee and tea, such as café de olla or agua de
                  tila, while others may prefer more modern, international
                  styles. There is also a wide range of specialty and gourmet
                  coffee and tea options available in Mexico, which appeal to
                  more discerning customers.
                </dd>
                <dd>
                  In general, coffee and tea customers in Mexico tend to be
                  diverse in terms of age, gender, and socio-economic status,
                  with a wide range of factors influencing their purchasing
                  habits. Some may be motivated by convenience, while others may
                  be more interested in quality, variety, or sustainability.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab === "business" && (
          <>
            <section>
              <p className="section-title">Business model</p>
              <div className="section-img">
                <img src={imageUrl("business.png")} alt="" />
              </div>
              <dl>
                <dd>
                  Java Times Caffe offers best coffee and tea products available
                  as well Sandwicks, Fresh baked godos & Snaks
                </dd>
                <dd>
                  JAVA TIMES CAFFE is a specialty coffee shop that offers its
                  customers one of the finest coffees and teas in the world.
                </dd>
                <dd>
                  JAVA TIMES CAFFE is a specialty coffee shop that offers its
                  customers one of the finest coffees and teas in the world.
                  Members of this coffee shop franchise serve their aromatic
                  specialties in a relaxing atmosphere. The success of the
                  franchise is due to the dynamic commitment of our employees,
                  and the enormous respect we show to both customers and
                  workers.
                </dd>
                <dd>
                  While most people enjoy the good atmosphere and quality of the
                  coffee sold at JTC, many people don't realize that this
                  company also cares about those who grow the coffee beans. JAVA
                  TIMES CAFFE{" "}
                </dd>
                <dd>
                  Now, Antonio Leite, owner of JAVA TIMES CAFFE, is ready to
                  re-launch his business with an operating concept that is very
                  different from the rest. Adapting the concept to more modern
                  times, JTC is ready to set aside the averages of competing
                  third wave coffee companies.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab === "prototype" && (
          <>
            <section>
              <p className="section-title">Prototype Store</p>
              <div className="section-img">
                <img src={imageUrl("business.png")} alt="" />
              </div>
              <dl>
                <dt>
                  Same Great Quality: New Concept tested for 5 years A success
                  story
                </dt>
                <dd>
                  The new JAVA TIMES CAFFE is located in Torreon, Coahuila, near
                  Plaza Mayor. Located in the center of the city, Plaza Mayor is
                  a favorite meeting point. JTC looks forward to attracting
                  young people to the wonderful decor and aromas of our cafes
                  and other delights.{" "}
                </dd>
                <dd>
                  Imagine the smell of fresh handmade bagels, hot pizzetas or
                  Italian-style paninis wafting through our doors and drifting
                  through the aisles. Let the wonderful aroma of the finest
                  artisan roasted coffee lure you in - here you'll be able to
                  sit back and enjoy that perfect cup of coffee, or enjoy a cup
                  of our hand-ground organic gourmet teas in a quiet, friendly
                  setting. Our new café offers a wider selection of food and
                  beverages than we had previously.
                </dd>
                <dd>
                  The trend in coffee shops in recent years has been for a
                  vintage style filled with wooden elements and metallic
                  objects. Our new store will feature these elements in our
                  modern décor, as well as in our furniture. The Plaza Mayor
                  store may look different from our previous stores, but the
                  quality of our products has not changed at all.
                </dd>
                <dd>
                  We still offer the same great tasting coffees. We have simply
                  added a lot of new products to make every JTC experience as
                  unique as it can be. Another special feature we've added is
                  new labels with hand-painted designs on our coffee, tea, and
                  chocolate packages.{" "}
                </dd>
                <dt>Working and investing in Java Times Caffé</dt>
                <dd>
                  Each Java Times Caffé coffee shop is governed by a fine-tuned
                  operating scheme.
                </dd>
                <dd>
                  Teams of a minimum of two and a maximum of five members are in
                  charge of attending to the different sections of each
                  location, taking orders and maintaining equipment and
                  facilities in optimal conditions.
                </dd>
                <dd>
                  Working at Java Times Caffé brings benefits beyond the
                  obvious. The brand's employee gets free drinks, discounts on
                  products and the opportunity to purchase JTC Shares, the
                  company's virtual currency.
                </dd>
                <dd>
                  Java Times Caffé coffee shops gain customer loyalty from their
                  staff.
                </dd>
                <dd>
                  It is normal to know almost nothing about them. The "almost"
                  is usually due to the fact that, even if it is unconsciously,
                  the client appreciates that the coffee served completely
                  satisfies his expectations.
                </dd>
                <dd>
                  The protagonists of these lines work behind the counter and
                  only leave the preparation area to take the steaming cups and
                  the requested food to the tables.
                </dd>
                <dd>
                  The clientele, busy waiting for the dark delight with JTC
                  quality to reach their hands and, once it appears, to process
                  such a delicious habit, hardly notices their presence.
                </dd>
                <dd>
                  To fully appreciate the task performed by these ambassadors of
                  the Mexican brand, a couple of questions should be asked: what
                  makes a Java Times Caffé coffee shop run smoothly? And what
                  goes on behind the counter?
                </dd>
                <dd>
                  JTC employees are the major cogs in the daily operation.{" "}
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab === "market" && (
          <>
            <section>
              <p className="section-title">Market</p>
              <div className="section-img">
                <img src={imageUrl("market.png")} alt="" />
              </div>
              <dl>
                <dt>Coffee Market size in Mexico</dt>
                <dd>
                  The coffee market in Mexico is a significant and important
                  industry, with a long history and a strong tradition of coffee
                  production and consumption. According to data from the
                  International Coffee Organization, Mexico is one of the top
                  coffee-producing countries in the world, with an annual
                  production of around 6 million 60-kilogram bags of coffee in
                  recent years.
                </dd>
                <dd>
                  The coffee market in Mexico is also a significant contributor
                  to the country's economy, with coffee exports generating
                  billions of dollars in revenue each year. In addition to
                  producing coffee for export, Mexico is also a significant
                  market for coffee consumption, with a large and growing
                  population of coffee drinkers.
                </dd>
                <dd>
                  There is no single measure of the size of the coffee market in
                  Mexico, as it can be influenced by a wide range of factors
                  such as production, exports, consumption, and pricing.
                  However, it is clear that the coffee industry is an important
                  part of Mexico's economy and culture, and it is likely to
                  continue to be so in the future.
                </dd>
                <dt>Tea market size in Mexico</dt>
                <dd>
                  The tea market in Mexico is a significant and growing
                  industry, with a long tradition of tea production and
                  consumption in the country. According to data from the Food
                  and Agriculture Organization of the United Nations (FAO),
                  Mexico is the world's 16th largest tea producer, with an
                  annual production of around 40,000 metric tons of tea.
                </dd>
                <dd>
                  The tea market in Mexico is also a significant contributor to
                  the country's economy, with tea exports generating millions of
                  dollars in revenue each year. In addition to producing tea for
                  export, Mexico is also a significant market for tea
                  consumption, with a large and growing population of tea
                  drinkers.
                </dd>
                <dd>
                  There is no single measure of the size of the tea market in
                  Mexico, as it can be influenced by a wide range of factors
                  such as production, exports, consumption, and pricing.
                  However, it is clear that the tea industry is an important
                  part of Mexico's economy and culture, and it is likely to
                  continue to be so in the future.
                </dd>
                <dt>Bakery market size in Mexico</dt>
                <dd>
                  The bakery market in Mexico is a significant and growing
                  industry, with a long tradition of bread and pastry production
                  and consumption in the country. According to data from the
                  Mexican Association of the Bakery, Confectionery, and Related
                  Industries (AMFAC), the bakery industry in Mexico is a major
                  contributor to the country's economy, with sales of over $15
                  billion in 2019.
                </dd>
                <dd>
                  The bakery market in Mexico is characterized by a wide variety
                  of products and a diverse range of consumers. Traditional
                  Mexican breads and pastries such as pan dulce and conchas are
                  popular, as are more modern, international products such as
                  croissants and bagels. The bakery market in Mexico is also
                  influenced by trends in health and wellness, with a growing
                  demand for healthier, whole grain, and gluten-free products.
                </dd>
                <dd>
                  There is no single measure of the size of the bakery market in
                  Mexico, as it can be influenced by a wide range of factors
                  such as production, consumption, and pricing. However, it is
                  clear that the bakery industry is an important part of
                  Mexico's economy and culture, and it is likely to continue to
                  be so in the future
                </dd>
                <dt>Sándwich market size in mexico</dt>
                <dd>
                  The sandwich market in Mexico is a significant and growing
                  industry, with a wide variety of products and a diverse range
                  of consumers. According to data from the Mexican Association
                  of the Bakery, Confectionery, and Related Industries (AMFAC),
                  the sandwich market in Mexico is a major contributor to the
                  country's economy, with sales of over $7 billion in 2019.
                </dd>
                <dd>
                  The sandwich market in Mexico is characterized by a wide
                  variety of products, including traditional Mexican sandwiches
                  such as tortas and tacos, as well as more modern,
                  international products such as paninis and wraps. The sandwich
                  market in Mexico is also influenced by trends in health and
                  wellness, with a growing demand for healthier, whole grain,
                  and vegetarian options.
                </dd>
                <dd>
                  There is no single measure of the size of the sandwich market
                  in Mexico, as it can be influenced by a wide range of factors
                  such as production, consumption, and pricing. However, it is
                  clear that the sandwich industry is an important part of
                  Mexico's economy and culture, and it is likely to continue to
                  be so in the future.
                </dd>
                <dt>Market size in billions of the coffee and tea industry </dt>
                <dd>
                  According to data from Mordor Intelligence, the global coffee
                  market was valued at approximately $48.2 billion in 2020 and
                  is expected to reach a value of $78.9 billion by 2025, growing
                  at a compound annual growth rate (CAGR) of 10.6%. The global
                  tea market, on the other hand, was valued at approximately
                  $20.8 billion in 2020 and is expected to reach a value of
                  $32.9 billion by 2025, growing at a CAGR of 8.6%.
                </dd>
                <dd>
                  It is worth noting that these figures represent the overall
                  market size for coffee and tea and do not take into account
                  the various subsegments within each industry, such as
                  specialty coffee, instant coffee, and black tea, among others.
                  Additionally, these figures may vary depending on the source
                  and the specific time period being considered.
                </dd>
              </dl>
            </section>
          </>
        )}

        {subTab == "competition" && (
          <>
            <section>
              <p className="section-title">Competition</p>
              <div className="section-img">
                <img src={imageUrl("competition.png")} alt="" />
              </div>
              <dl>
                <dt>
                  Starbucks may be a competitor for Java Times Caffe even do we
                  have a big number of clients that have converted to Java Times
                  Caffe{" "}
                </dt>
                <dd>
                  It is difficult to accurately estimate the market share of
                  Starbucks in Mexico, as it can vary depending on the specific
                  region or market being considered and may also change over
                  time. Additionally, there are many other factors that can
                  influence market share, such as the presence of competing
                  coffee chains and local preferences for different types of
                  coffee and cafes.
                </dd>
                <dd>
                  That being said, Starbucks has a significant presence in
                  Mexico, with over 600 stores in the country as of 2021. The
                  company has been expanding its presence in Mexico in recent
                  years, and it has been well-received by many Mexican
                  consumers. However, it is likely that Starbucks faces
                  competition from other coffee chains and local cafes in
                  Mexico, so it is possible that its market share may not be as
                  high as it is in some other countries.
                </dd>
                <dt>Vision and strategy of Java Times Caffe</dt>
                <dd>
                  Java Times Caffe is a Mexican coffee chain that was founded in
                  1999 and has rapidly expanded its presence in North of Mexico
                  and plan to go internationally. The company's vision is to
                  become the leading coffee chain in Mexico and the world by
                  sharing Java Times Caffe Business model with everyone and to
                  provide convenient, high-quality coffee experiences to its
                  customers and Partners.
                </dd>
                <dd>
                  To achieve this vision, Java Times Caffe has pursued an
                  aggressive expansion strategy, opening 300 stores in a short
                  period of time no more tan 5 years from today and using
                  technology and innovative business models to differentiate
                  itself from traditional coffee chains. The company has also
                  focused on leveraging data and analytics to better understand
                  customer preferences and optimize its operations.
                </dd>
                <dd>
                  Java Times Caffe´s strategy has been centered around offering
                  a wide variety of coffee and other beverages through various
                  channels, including its own stores, delivery services, Online
                  Store. The company has also emphasized convenience and speed,
                  with a focus on quick service and efficient operations.
                </dd>
                <dd>
                  Overall, Java Times Caffe's vision is to become the Worls loca
                  coffee shop, leading coffee chain in Mexico and soon the resto
                  f the world by offering high-quality, convenient coffee
                  experiences and leveraging technology and data to drive growth
                  and efficiency and provide a piece os the pie to everyone.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab == "notice" && (
          <>
            <section>
              <p className="section-title">Notice to Potential Investors</p>

              <dl>
                <dd>
                  Please be advised that the information provided on this
                  website regarding investment opportunities in Java Times Caffe
                  is for general informational purposes only and should not be
                  considered investment advice. Any decision to invest in the
                  business should be based on your own due diligence and in
                  consultation with a financial professional. Java Times Caffe
                  and its management team do not provide investment advice and
                  do not make any representations or warranties as to the
                  accuracy or completeness of any information on this website or
                  any linked site.
                </dd>
                <dd>
                  Investing in a startup or small business carries inherent
                  risks, and potential investors should be aware that they may
                  lose some or all of their investment. We encourage you to
                  consult with a financial advisor before making any investment
                  decisions. "
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab == "return" && (
          <>
            <section>
              <p className="section-title">Expected Return</p>

              <dl>
                <dd>
                  Minimum investment amount: The smallest amount of money that
                  an investor can put into the business is 5000 Mexican Pesos
                </dd>
                <dd>
                  The expected return on investment (ROI) for this business
                  ranges from 10% to 38% per year or more. It's important to
                  note that the actual ROI may be different than the expected
                  ROI and can be affected by various factors.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab == "t&c" && (
          <>
            <section>
              <p className="section-title">
                Terms and Condition for JTC Tokens
              </p>

              <dl>
                <dd>
                  JTC Tokens are a type of digital currency or rewards program
                  that can be used to purchase food and coffee at Java Times
                  Caffe locations or through the company's JTC App or e-commerce
                  platform. Customers can earn JTC Tokens by making purchases at
                  Java Times Caffe or participating in special promotions or
                  events.
                </dd>
                <dd>
                  To use JTC Tokens to make purchases, customers can add them to
                  their account through the JTC App or e-commerce platform, and
                  then use them to pay for food and coffee at checkout. JTC
                  Tokens may be subject to expiration or other restrictions, and
                  they may not be redeemable for cash or other goods or
                  services.
                </dd>
                <dd>
                  It's worth noting that the details and terms of the JTC Token
                  rewards program may vary depending on the specific policies of
                  Java Times Caffe. It's a good idea to review the terms and
                  conditions of the program before participating, to ensure that
                  you understand how the tokens can be earned and used.
                </dd>
              </dl>
            </section>
          </>
        )}
        {subTab == "bonus" && (
          <>
            <section>
              <p className="section-title">JTC Bonus </p>

              <dl>
                <dt>
                  In addition to your Comunity Funding SAFE, you'll receive JTC
                  Bonus for investing in JTC Mexico Expansion Project.
                </dt>
                <dd>
                  Minimum investment amount: The smallest amount of money that
                  an investor can put into the business is 5000 Mexican Pesos
                </dd>
                <dd>
                  The expected return on investment (ROI) for this business
                  ranges from 10% to 38% per year or more. It's important to
                  note that the actual ROI may be different than the expected
                  ROI and can be affected by various factors.
                </dd>

                <dt>Invest</dt>
                <dd>$10,000 MX Pesos</dd>
                <dt>Receive</dt>
                <dd>
                  $500 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend in
                  food and Coffee in our JTC App or E-commerce Invitation to all
                  coffee shop inaugurations Annual investor party Invitation
                </dd>
                <dt>Invest</dt>
                <dd>$50,000 MX Pesos</dd>
                <dt>Receive</dt>
                <dd>
                  $1,000 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend
                  in food and Coffee in our JTC App or E-commerce Invitation to
                  all coffee shop inaugurations Annual investor party Invitation
                </dd>
                <dt>Invest</dt>
                <dd>$100,000 MX Pesos</dd>
                <dt>Receive</dt>
                <dd>
                  $2,000 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend
                  in food and Coffee in our JTC App or E-commerce Invitation to
                  all coffee shop inaugurations Engraved name in 1 coffee shop
                  of your choice Annual investor party Invitation
                </dd>
                <dt>Invest</dt>
                <dd>$250,000 MX Pesos</dd>
                <dt>Receive</dt>
                <dd>
                  $3,750 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend
                  in food and Coffee in our JTC App or E-commerce Invitation to
                  the ribbon cutting of all coffee shop inaugurations Engraved
                  name in 2 coffee shop of your choice Annual investor party
                  Invitation
                </dd>
                <dt>Invest</dt>
                <dd>$500,000 MX Pesos</dd>
                <dt>Receive</dt>
                <dd>
                  $7,500 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend
                  in food and Coffee in our JTC App or E-commerce Invitation to
                  the ribbon cutting of all coffee shop inaugurations Engraved
                  name in up to 5 coffee shop of your choice Annual investor
                  party Invitation
                </dd>
                <dt>Invest</dt>
                <dd>$1,000,000 MX Pesos.</dd>
                <dt>Receive</dt>
                <dd>
                  $15,000 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend
                  in food and Coffee in our JTC App or E-commerce Invitation to
                  the ribbon cutting of all coffee shop inaugurations Engraved
                  name in up to 10 coffee shop of your choice Annual investor
                  party Invitation Hotel Room for two persons
                </dd>

                <dt>Invest</dt>
                <dd>$5,000,000 MX Pesos.</dd>
                <dt>Receive</dt>
                <dd>
                  $75,000 JTC Tokens in Java Times Caffe REWARD PROGRAM To spend
                  in food and Coffee in our JTC App or E-commerce
                </dd>
              </dl>
            </section>
          </>
        )}

        {subTab == "opportunity" && (
          <>
            <section>
              <p className="section-title">Investment Opportunities</p>
            </section>
          </>
        )}
      </div>
    </>
  );
};

export default OpenPitch;
