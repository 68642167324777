import AdminNewSidebar from "components/admin/AdminNewSidebar";
import React, {useState} from "react";
import {Box, Container} from "@mui/material";
import {Header} from "components";
import useTranslation from "hooks/useTranslation";
import {Button, Form} from "react-bootstrap";
import {toast} from "react-toastify";
import apis from "../../services";

export default function Dividend() {
    const {t} = useTranslation();
    const [dividend, setDividend] = useState(0);

    const submitHandler = async (event) => {
        event.preventDefault();
        try {
            const response = await apis.generateDividend({dividend});
            if (response) {
                toast.success(response?.data);
                setDividend(0);
            }
        } catch (e) {
            console.log(`Error in generateDividend`, e);
        }

    }

    return <>
        <Box className="dashboard-main">
            <AdminNewSidebar/>
            <div className="dashboard-section-main">
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                    className="custom-scroll"
                >
                    <Header/>
                    <Container maxWidth="sm" sx={{mt: 0, mb: 4}}>
                        <div className="bg-light p-5">
                            <h3 className=""> Dividend </h3>
                            <Form onSubmit={submitHandler}>
                                <Form.Group className="mb-3" controlId="formGroupEmail">
                                    <Form.Label>How much dividend you want to give?</Form.Label>
                                    <Form.Control
                                        type="number"
                                        value={dividend}
                                        placeholder="Enter Dividend amount"
                                        onChange={(e) => setDividend(e.target.value)}
                                    />
                                </Form.Group>
                                <div className="">
                                    <Button className="w-50" variant="outline-danger" type="submit">
                                        Submit
                                    </Button>
                                </div>

                            </Form>
                        </div>
                    </Container>
                </Box>
            </div>
        </Box>
    </>
}