import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import imageUrl from "utils/imageUrl";
import { RiDoubleQuotesL, RiDoubleQuotesR } from 'react-icons/ri'

const TestimonialCard = ({author, img, desc}) => {
  return (
    <>
    <div className="testimonial-card-wrapper p-3">

      <div className="testimonial-card">
        <RiDoubleQuotesL className="quote-icon"/>
        <RiDoubleQuotesR className="quote-icon"/>
        <Row>
          <Col sm={4}>
            <div className="image-wrapper">
              <img src={imageUrl(img)} alt="" />
            </div>
          </Col>
          <Col sm={8}>
            <div className="testimonial-body">
              <div className="rating-stars">
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
                <AiFillStar />
              </div>
              <p className="custom-card-title">{author}</p>
              <p className="card-desc">
                {desc}
              </p>
              <div className="card-line"></div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </>
  );
};

export default TestimonialCard;
