import {Col, Container, Form, Row} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import InvestorForm from '../components/forms/InvestorForm';
import LegalEntityForm from '../components/forms/LegalEntityForm';
import {FORM_NAMES} from '../assets/constants';
import {useLocation, useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import { Header, Sidebar } from 'components';
import useTranslation from 'hooks/useTranslation';

const Registration = () => {
    const navigate = useNavigate();
    const {search, pathname} = useLocation();
    const { language, setLanguage, t } = useTranslation();

    const searchParams = new URLSearchParams(search);
    const {type} = Object.fromEntries(searchParams)

    const [formName, setFormName] = useState(type);

    const {user} = useSelector(store => store.user);

    useEffect(() =>{
        if(!user){
            navigate('/login', {state: {from: pathname}})
        }
    },[user])

    const onFormChangeHandler = (e) => setFormName(e.target.value);

    const renderForm = (form) => {
        if (form === FORM_NAMES.investor) return <InvestorForm/>
        else if (form === FORM_NAMES.legalEntity) return <LegalEntityForm/>
        else return <LegalEntityForm/>
    }

    return (
        <div className="landing-main light-bg">
        <Sidebar/>
        <div className="landing-page landing-main-page custom-scroll">
         <Header/>
        <Container className=' pb-5'>
            <div className="investor-form custom-box p-5">
                <Row>
                    <Col md={4}/>
                    <Col md={4}>
                        <Form.Group className="mb-3 custom-select">
                            <Form.Select id="formName" onChange={onFormChangeHandler} value={formName}>
                                {Object.entries(FORM_NAMES).map(([key, name], index) => (
                                    <option key={index} value={key} disabled={name === 'JTC Employee Form'}>
                                        {t(`labels.${key}`)}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {renderForm(FORM_NAMES[formName])}
                </Row>
            </div>
        </Container>
        </div>

        </div>
    );
};

export default Registration;
