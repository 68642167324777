import React, { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LocationImg } from "../components/Images";
import apis from "../services/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import utils from "../utils";
import { setUser } from "redux/slices/userSlice";
import useTranslation from "hooks/useTranslation";

function InvestCard(props) {
  const {
    toggleModal,
    name,
    title,
    location: { address },
    description,
    _id,
    image,
    toggleInvestmentModal,
  } = props;
  let dispatch = useDispatch();
  const { user } = useSelector((store) => store.user);

  const [data, setUserData] = useState();

  const [more, setMore] = useState(false);

  const { language, setLanguage, t } = useTranslation();

  const fetchUser = async () => {
    const res = await apis.getProfile(user?.id);
    if (res.data.status) setUserData(res.data.user);
    dispatch(setUser({ id: res.data.user?._id, ...res.data.user }));
  };

  useEffect(() => {
    (async () => {
      await fetchUser();
    })();
  }, []);

  const handleSubmit = () => {
    if (!user) {
      return toast.info("Register yourself first to invest");
    } else if (data?.isRequested === true && data?.isVerified === false) {
      return toast.info("Your status of investment is pending");
    } else if (data?.isRequested === false && data?.isVerified === true) {
      toggleInvestmentModal();
    } else if (data?.isVerified) {
      toggleModal(_id);
    } else {
      toggleInvestmentModal();
    }
  };

  return (
    <>
      <Col sm={6} lg={3} className="mt-3">
        <Fade bottom>
          <div className="invest-now-card shop-item">
            <Link to={"#"} className="small-shade">
              <div>
                <div className="image-section">
                  <figure>
                    <img
                      src={image}
                      alt={name}
                      onError={(e) =>
                        (e.currentTarget.src = utils._img("404.png"))
                      }
                      width={"100%"}
                    />
                    <figcaption className="row">
                      <div className="col-2">
                        <img
                          alt=""
                          src={LocationImg}
                          className="location-icon"
                        />
                      </div>
                      <div className="col-10">
                        <span>{address}</span>
                      </div>
                    </figcaption>
                  </figure>
                </div>
                <h4>{title}</h4>
                <p className={`invest-card-desc ${more ? "more" : "less"}`}>
                  {description}
                </p>
                <div className="show-more" onClick={() => setMore(!more)}>
                  {more ? <p>{t("buttons.show_less")}</p> : <p>{t("buttons.show_more")}</p>}
                </div>
                <button
                  className="simple-square-btn d-block"
                  onClick={handleSubmit}
                >
                  {t("buttons.invest_now")}
                </button>
              </div>
            </Link>
          </div>
        </Fade>
      </Col>
    </>
  );
}

export default InvestCard;
