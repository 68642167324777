import React, { useEffect, useState } from 'react'
import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import { useDispatch } from "react-redux";
import { getAllUser } from '../../redux/slices/adminSlice';
import apis from '../../services';
import { useParams } from 'react-router-dom';
import AdminNewSidebar from './AdminNewSidebar';
import Header from 'components/Header';
import {Container} from "@mui/material";
import useTranslation from 'hooks/useTranslation';


const UserDetails = () => {
    const { language, setLanguage, t } = useTranslation();
    const [dataUser, setData] = useState();
    const param = useParams();
    const userId = param.id;


    const fetchUser = async () => {
        const res = await apis.getProfile(userId);
        if (res.data.status) {
            setData(res.data.user)
        }
    }

    useEffect(() => {
        (async () => {
            await fetchUser()
        })();
    }, []);


    // useEffect(() => {
    //     dispatch(getAllUser());
    //     const result = dispatch(apis.getProfile(userId)).then((res) => {
    //         setData(res?.payload)
    //     })
    // }, []);

    return (
        <>
            <Box className="dashboard-main">
                <AdminNewSidebar />
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header/>
                    <Container>
                    <h3 className="dashboard-title dark-text text-start mb-0">{t("titles.user_details")}</h3>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">{t("fields.sno")}</TableCell>
                                        <TableCell align="center">{t("fields.name")}</TableCell>
                                        <TableCell align="center">{t("fields.type")}</TableCell>
                                        <TableCell align="center">{t("fields.last_name")}</TableCell>
                                        <TableCell align="center">{t("fields.phone")}</TableCell>
                                        <TableCell align="center">{t("fields.fathers_name")}</TableCell>
                                        <TableCell align="center">{t("fields.mothers_name")}</TableCell>
                                        <TableCell align="center">{t("fields.email")}</TableCell>
                                        <TableCell align="center">{t("fields.passport")}</TableCell>
                                        <TableCell align="center">{t("fields.passport_expire_date")}</TableCell>
                                        <TableCell align="center">{t("fields.bank_account")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} style={{ padding: 10 }}>
                                        <TableCell align="center"> {1} </TableCell>
                                        <TableCell align="center">{dataUser?.name}</TableCell>
                                        <TableCell align="center">{dataUser?.type}</TableCell>
                                        <TableCell align="center">{dataUser?.lastName}</TableCell>
                                        <TableCell align="center">{dataUser?.phone}</TableCell>
                                        <TableCell align="center">{dataUser?.fatherName}</TableCell>
                                        <TableCell align="center">{dataUser?.motherName}</TableCell>
                                        <TableCell align="center">{dataUser?.email}</TableCell>
                                        <TableCell align="center">{dataUser?.passport}</TableCell>
                                        <TableCell align="center">{dataUser?.passportExpireDate}</TableCell>
                                        <TableCell align="center">{dataUser?.bankAccount}</TableCell>

                                    </TableRow>

                                </TableBody>
                            </Table>
                    </Container>
                    </Box>
                </div>
            </Box>
        </>

    )
}

export default UserDetails