import {Box, Container, Grid, Tab, Tabs, TextField, Typography} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "../../components/admin/AdminSidebar";
import {Fragment, useState} from "react";
import {FORM_NAMES} from "../../assets/constants";
import InvestorForm from "../../components/forms/InvestorForm";
import LegalEntityForm from "../../components/forms/LegalEntityForm";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
// import InvestorForm from "../InvestorForm";


const TabPanel = ({children, value, index, ...rest}) =>
    value === index
        ? (<Fragment>{children}</Fragment>)
        : null

/*function TabPanel({children, value, index, ...rest}) {


    return (

        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}*/

const AddUser = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => setValue(newValue);
    return (
        <>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        height: "100vh",
                        overflow: "auto",
                    }}
                    className="custom-scroll"
                >
                     <Header/>
                    <Container>
                    <h3 className="dashboard-title dark-text text-start mb-0">Add User</h3>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered
                              textColor="secondary"
                              indicatorColor="secondary"
                        >
                            {Object.values(FORM_NAMES).map((name, index) => <Tab key={index} label={name} disabled={name === FORM_NAMES.jtc}/>)}
                        </Tabs>
                    </Box>
                    </Container>
                    <Container>
                        <Grid container my={2}>
                            <TabPanel value={value} index={0}>
                                <InvestorForm isCreatedByAdmin/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <LegalEntityForm/>
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                JTC Employee
                            </TabPanel>
                        </Grid>
                    </Container>
                </Box>
                </div>
            </Box>
        </>
    )
};


export default AddUser
