import React, {useEffect, useState} from "react";
import {Box, Container, Grid} from "@mui/material";
import apis from "services";
import {DataGrid} from "@mui/x-data-grid";
import StoresTable from "components/datagrid/StoresTable";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import Toolbar from "../../components/@material-extend/CustomToolbar";
import ImagePreview from "../../components/modals/ImagePreview";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import {Header} from "components";
import useTranslation from "hooks/useTranslation";

const Stores = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [image, setImage] = useState("");
    const [stores, setStores] = useState([]);
    const [imageModal, setImageModal] = useState(false);
    const [selectionModel, setSelectionModel] = useState([]);

    const closeImageModal = (image) => {
        if (image) setImage(image);
        setImageModal((prevState) => !prevState);
    };

    const fetchAllStores = async () => {
        const res = await apis.getAllStores();
        setStores(res?.data?.stores);
    };

    const handleDisableStore = async (id) => {
        const res = await apis.disableStore(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllStores();
        }
    };
    const handleEnableStore = async (id) => {
        const res = await apis.enableStore(id);
        if (res.data.status) {
            toast.success(res.data.message);
            await fetchAllStores();
        }
    };
    const handleDeleteStore = async (id) => {
        const formattedId = typeof id === "string" ? [id] : id;
        const res = await apis.deleteStores(formattedId);
        if (res?.data?.status) {
            toast.success(res.data?.message);
            setSelectionModel([]);
            await fetchAllStores();
        } else toast.warning(res.data?.message);
    };

    useEffect(() => {
        (async () => {
            await fetchAllStores();
        })();
    }, []);

    const methods = {
        handleEnableStore,
        handleDisableStore,
        navigate,
        handleDeleteStore,
    };
    const {rows, columns} = StoresTable(stores, methods, closeImageModal);

    const DeleteStoreButton = ({rows}) => {
        const ids = rows.length === 0 ? [] : [...rows.map((record) => record._id)];

        return (
            // <button className='btn danger-btn' onClick={() => handleDeleteStore(ids)}>Delete Stores</button>
            <></>
        );
    };

    const AddStoreButton = () => {
        return <></>;
    };

    const NewToolbar = () => (
        <Toolbar buttons={[<AddStoreButton/>, <DeleteStoreButton/>]}/>
    );

    return (
        <>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header/>
                        <Container maxWidth="lg" sx={{mt: 0, mb: 4}}>
                            <div className="flex-with-title">
                                <h3 className="dashboard-title">
                                    {t("titles.stores")}
                                </h3>
                                <Link to={"/admin/stores/add"} className="lp-btn">
                                    {t("buttons.add_store")}
                                </Link>
                            </div>
                            <Grid
                                item
                                container
                                justifyContent="flex-end"
                                className="mt-4"
                                sx={{height: "600px"}}
                            >
                                <DataGrid
                                    checkboxSelection
                                    rows={rows}
                                    columns={columns}
                                    onSelectionModelChange={(newSelectionModel) =>
                                        setSelectionModel(newSelectionModel)
                                    }
                                    selectionModel={selectionModel}
                                    components={{Toolbar: NewToolbar}}
                                    getRowClassName={(params) =>
                                        params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                                    }
                                />
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </Box>
            <ImagePreview status={imageModal} close={closeImageModal} image={image}/>
        </>
    );
};

export default Stores;
