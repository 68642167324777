import {Outlet} from "react-router-dom";
import useLocationNavigation from "../hooks/useLocationNavigation";

export default function Language() {
    useLocationNavigation();


    //Check if no language defined then assign default language
    //Check if no language defined then assign default language
    return <Outlet/>
}