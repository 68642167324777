import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "components/admin/AdminSidebar";
import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apis from "services";
import TransactionTable from "../../components/datagrid/TransactionTable";
import { DataGrid } from "@mui/x-data-grid";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import useTranslation from "hooks/useTranslation";

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const { language, setLanguage, t } = useTranslation();


  const fetchTransactions = async () => {
    const { data } = await apis.getTransactions();
    if (data?.status) {
      setTransactions(data.transactions);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTransactions();
    })();
  }, []);

  const { rows, columns } = TransactionTable(transactions);

  return (
    <Box className="dashboard-main">
      <AdminNewSidebar />
      <div className="dashboard-section-main">
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
          className="custom-scroll"
        >
          <Header />
          <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
            <h3 className="dashboard-title">{t("titles.transactions")}</h3>
            <Grid
              item
              container
              className="mt-4"
              justifyContent="flex-end"
              sx={{ height: "600px" }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                }
              />
            </Grid>
          </Container>
        </Box>
      </div>
    </Box>
  );
};

export default Transactions;
