import React, { useEffect, useState } from "react";
import PersonItem from "./PersonItem";
import Slider from "react-slick";
import apis from "services";

const InvestorSlider = () => {
  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],

    // nextArrow: <PrimaryNextArrow />,
    // prevArrow: <PrimaryPrevArrow />,
  };

  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await apis.getRecentUserInvest();
    setData(res?.data?.recentUser);
  };
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  console.log(data, "data");

  return (
    <>
      <Slider {...settings}>
        {data?.length > 0 && data.map((item,index) => {
          return (
            <>
              {" "}
              <PersonItem key={index} data={item}/>
            </>
          );
        })}
        </Slider>
    </>
  );
};

export default InvestorSlider;
