import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

const OpenHeader = ({ tab, setTab, subTab, setSubTab }) => {
  return (
    <>
      <div className="open-header">
        <Row>
          <Col>
            <ul className="">
              <li
                className={`${!tab && "active"}`}
                onClick={() => {
                  setTab(0);
                  setSubTab('problem');
                }}
              >
                Pitch
              </li>
              {!tab && (
                <>
                  <ul className="open-header-sub-menu">
                    <li className={subTab === 'problem' && 'active'} onClick={()=>{setSubTab('problem')}}>Problem</li>
                    <li className={subTab === 'solution' && 'active'} onClick={()=>{setSubTab('solution')}}>Solution</li>
                    <li className={subTab === 'traction' && 'active'} onClick={()=>{setSubTab('traction')}}>Traction</li>
                    <li className={subTab === 'customers' && 'active'} onClick={()=>{setSubTab('customers')}}>Customers</li>
                    <li className={subTab === 'business' && 'active'} onClick={()=>{setSubTab('business')}}>Business Model</li>
                    <li className={subTab === 'prototype' && 'active'} onClick={()=>{setSubTab('prototype')}}>Prototype store</li>
                    <li className={subTab === 'market' && 'active'} onClick={()=>{setSubTab('market')}}>Market</li>
                    <li className={subTab === 'competition' && 'active'} onClick={()=>{setSubTab('competition')}}>Competition</li>
                  </ul>
                </>
              )}

              <li
                className={`${tab == 1 && "active"}`}
                onClick={() => {
                  setTab(1);
                  setSubTab('opportunity')
                }}
              >
                Discussion
              </li>
              {tab === 1 && (
                <>
                  <ul className="open-header-sub-menu">
                    <li className={subTab === 'opportunity' && 'active'} onClick={()=>{setSubTab('opportunity')}}>Investment Opportunities</li>
                    <li className={subTab === 'notice' && 'active'} onClick={()=>{setSubTab('notice')}}>Notice to Potential Investors</li>
                    <li className={subTab === 'return' && 'active'} onClick={()=>{setSubTab('return')}}>Expected Return</li>
                  </ul>
                </>
              )}
              <li
                className={`${tab == 2 && "active"}`}
                onClick={() => {
                  setTab(2);
                }}
              >
                Updates
              </li>
              <li
                className={`${tab == 3 && "active"}`}
                onClick={() => {
                  setTab(3);
                }}
              >
                Reviews
              </li>
              <li
                className={`${tab == 4 && "active"}`}
                onClick={() => {
                  setTab(4);
                  setSubTab('bonus')
                }}
              >
                JTC Bonus
              </li>
              <li
                className={`${tab == 5 && "active"}`}
                onClick={() => {
                  setTab(5);
                  setSubTab('t&c')
                }}
              >
                Terms and Condition for JTC Tokens
              </li>
            </ul>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OpenHeader;
