import React from "react";
import imageUrl from "utils/imageUrl";

const BlogCardMain = ({title, desc, time, author, img}) => {
  return (
    <>
    <div className="blog-card-wrapper p-3">
      <div className="blog-card">
        <div className="blog-card-img">
          <div className="blog-card-img-inner w-100 h-100">
            <img src={imageUrl(img)} alt="" />
          </div>
        </div>
        <div className="blog-card-body-wrapper">
          <div>
            <p className="blog-card-title">{title}</p>
            <p className="card-desc">
              {desc}
            </p>

            <div className="blog-card-footer">
              <div className="icon-img">
                <img src={imageUrl('placeholder.png')} alt="" />
              </div>
              <div className="">
                <p className="card-person">{author}</p>
                <p className="card-time">{time} ago</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default BlogCardMain;
