import store from "../redux/store"

const createUtils = () => {

    const _img = (name = '') => `${process.env.PUBLIC_URL}/assets/images/${name}`;
    const isPasswordMatch = (password1, password2) => password1 === password2
    const checkAdminType = () => store.getState()?.['admin']?.['adminUser']?.['type']
    const hasLanguageInPathname = (pathname = '', languages = []) => languages.includes(pathname.split("/")?.[1]);

    return {isPasswordMatch, _img, checkAdminType, hasLanguageInPathname}
};


const utils = createUtils();

export default utils;
