import React from "react";
import imageUrl from "utils/imageUrl";


const TeamCard = ({img, name, title, desc}) => {
  return (
    <>
    <div className="t-card-item px-3 mx-auto">
      <div className="t-card-wrapper">
        <div className="t-card-img">
          <img src={imageUrl(img)} alt="" />
        </div>
        <p className="card-title">{name}</p>
        <p className="normal-p">{title}</p>
        <p className="t-card-desc">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Minima, hic! Lorem ipsum dolor sit amet.</p>
        <div className="card-icons">
          <div className="card-icon-wrapper">
            <div className="card-icon instagram">
              <i class="fa-brands fa-instagram "></i>
            </div>
          </div>
          <div className="card-icon-wrapper">
            <div className="card-icon in">
              <i class="fa-brands fa-linkedin-in"></i>{" "}
            </div>
          </div>
          <div className="card-icon-wrapper">
            <div className="card-icon twitter">
              <i class="fa-brands fa-twitter"></i>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TeamCard;
