import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getProfile } from "../../redux/slices/adminSlice";
import apis from "../../services";
import { Link, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  Delete,
  RemoveModerator,
  VerifiedUser,
  Visibility,
} from "@mui/icons-material";
import Toolbar from "components/@material-extend/CustomToolbar";
import { toast } from "react-toastify";
import CssBaseline from "@mui/material/CssBaseline";
import AdminSidebar from "../../components/admin/AdminSidebar";
import UsersTable from "../../components/datagrid/UsersTable";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import { Header } from "components";
import Container from "@mui/material/Container";
import useTranslation from "hooks/useTranslation";

export const Users = () => {
  const [users, setUsers] = useState([]);
  const { language, setLanguage, t } = useTranslation();
  const navigate = useNavigate();

  /*Methods*/
  const fetchAllUsers = async () => {
    const res = await apis.getAllUser();
    if (res.data.status) setUsers(res.data.users);
  };

  const deleteHandler = async (id) => {
    const res = await apis.deleteUser(id);
    if (res.data.status) {
      console.log(res);
      toast.success(res.data.message);
      await fetchAllUsers();
    }
  };

  const approveHandler = async (id) => {
    const res = await apis.approveUser(id);
    if (res.data.status) {
      console.log(res);
      toast.success(res.data.message);
      await fetchAllUsers();
    } else toast.warn(res.data.message);
  };

  useEffect(() => {
    (async () => {
      await fetchAllUsers();
    })();
  }, []);

  const NewToolbar = () => (
    <Toolbar
      buttons={[
        <Button
          variant="outlined"
          py={2}
          size="small"
          onClick={() => navigate("add")}
          color="error"
        >
          {t("buttons.add_user")}
        </Button>,
      ]}
    />
  );

  const { rows, columns } = UsersTable(users, {
    navigate,
    approveHandler,
    deleteHandler,
  });

  return (
    <>
      <Box className="dashboard-main">
        <AdminNewSidebar />
        <div className="dashboard-section-main">
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
            className="custom-scroll"
          >
            <Header />
            <Container maxWidth="lg" sx={{ mt: 0, mb: 4 }}>
              <div className="flex-with-title">
                <h3 className="dashboard-title">
                {t("titles.users")}

                </h3>
                <Link to={"/admin/users/add"} className="lp-btn">
                  {t("buttons.add_user")}
                </Link>
              </div>
              <Grid
                item
                container
                justifyContent="flex-end"
                className="mt-4"
                sx={{ height: "600px" }}
              >
                <DataGrid
                  checkboxSelection
                  rows={rows}
                  columns={columns}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
                  }
                />
              </Grid>
            </Container>
          </Box>
        </div>
      </Box>
    </>
  );
};

export default Users;
