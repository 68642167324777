import React, { useEffect, useState } from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import {
  Footer,
  StatBox,
  MainHeader,
  InvestorSlider,
  OpenSection,
  BlogCardMain,
  TestimonialCard,
} from "components";
import { MdOutlineAnnouncement } from 'react-icons/md'
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "hooks/useTranslation";
import apis from "services";
import Slider from "react-slick";
import { blogs, testimonials } from "./../dummyData";
import TeamCard from "components/TeamCard";
import imageUrl from "utils/imageUrl";
import { RiDoubleQuotesL } from "react-icons/ri";

const LandingPage = () => {
  const navigate = useNavigate();
  const { user } = useSelector((store) => store.user);
  const admin = useSelector((store) => store.admin);
  const handleScroll = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await apis.getTotalRecord();
    setData(res?.data);
  };
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  const settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings_2 = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  // const HOC = ({component: Component}) => {
  //   return <Component isVerified={true}/>
  // }

  // const messageNavigate = (path) => {
  //   return <>
  //     <NotVerifiedMessage />
  //   </>
  // }

  // useEffect(async()=>{
  //   const res = await apis.getTotalRecord();
  // console.log(res,"TOTAL")
  // },[])

  const { language, setLanguage, t } = useTranslation();

  const changeLanguage = (lng) => {
    setLanguage(lng);

    window.location.reload();
  };

  return (
    <>
      <div className="landing-main">
        {/* <Sidebar /> */}
        <div className={`landing-page landing-main-page custom-scroll`}>
          <div className="scroll-icon-wrapper" onClick={handleScroll}>
            <img
              src={imageUrl("scroll-icon.png")}
              alt=""
              className="scroll-icon"
            />
          </div>
          <div className="main-section-wrapper position-relative">
            <section className="main-section">
              <MainHeader />

              <Container className="h-100 main-container">
                <Row className="h-100">
                  <Col
                    md={6}
                    className="d-flex flex-column justify-content-center main-section-text"
                  >
                    <p className="section-title main-section-title mb-0 dark-text text-start">
                      <span className="highlight">Invest</span> in the{" "}
                      <span className="highlight">future</span> you believe in
                      {/* {t("titles.banner")}

                      <span className="courgette text-capitalize">
                        {" " + t("words.cup")}
                        <span className="red-text courgette">?</span>
                      </span> */}
                    </p>
                    <p className="normal-p w-75">
                      Access{" "}
                      <a href="#" className="underlined-text">
                        vetted investment opportunities
                      </a>{" "}
                      in JTC
                    </p>
                    {/* <p className="normal-p">{t("descs.banner")}</p> */}
                    <div className="rounded-input-group row my-4">
                      <input
                        type="text"
                        placeholder="your@gmail.com"
                        className="rounded-input col-7"
                      />
                      <button className="lp-btn col-5">Get Started</button>
                    </div>
                  </Col>
                  <Col
                    md={6}
                    className="d-flex align-items-center justify-content-center pe-5"
                  >
                    <div className="lp-phone-img-wrapper">
                      <img
                        src={imageUrl("landing-phone.png")}
                        alt=""
                        className="lp-shop-crop"
                        width={"100%"}
                      />
                      <StatBox
                        order={1}
                        label="Total Investors"
                        number={data?.totalInvestor}
                        icon="investor-icon.png"
                      />
                      <StatBox
                        order={2}
                        label="Total Stores Funded"
                        number={data?.totalStoreFundedAmount}
                        icon="stores-funded-icon.png"
                      />
                      <StatBox
                        order={3}
                        label="Total Shares"
                        number={data?.TOTAL_SHARES}
                        icon="total-shares-icon.png"
                      />
                      <StatBox
                        order={4}
                        label="Available Shares"
                        number={data?.totalAvailableShares}
                        icon="available-shares-icon.png"
                      />
                      <StatBox
                        order={5}
                        label="Stores Price"
                        number={data?.TOTAL_PRICE_PER_SHARE}
                        icon="stores-price-icon.png"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
          <section className="recent-investors">
            <Container>
              <InvestorSlider />
            </Container>
          </section>
          {/* 
          <section className="custom-section landing-stats  grey-bg">
            <Container>
              <LandingStats />
            </Container>
          </section> */}

          <section className="open-section pb-5">
            <OpenSection />
          </section>

          <section className="custom-section blog-section grey-bg">
            <Container>
              <p className="section-title text-uppercase dark-text">
                JAVATIMES BLOG
              </p>
              <Slider {...settings}>
                {blogs.map((blog) => {
                  return (
                    <>
                      <Link to="/blog">
                        <BlogCardMain
                          title={blog.title}
                          img={blog.img}
                          desc={blog.desc}
                          author={blog.author}
                          time={blog.time}
                        />
                      </Link>
                    </>
                  );
                })}
              </Slider>
            </Container>
          </section>

          {/* <section className="open-investments py-5">
            <Container>
              <p className="section-title text-start">Open Investments</p>
              <Row>
                <Col>
                  <Link>
                    <OpenCard />
                  </Link>
                </Col>
                <Col>
                  <Link>
                    <OpenCard />
                  </Link>
                </Col>
                <Col>
                  <Link>
                    <OpenCard />
                  </Link>
                </Col>
              </Row>
            </Container>
          </section> */}
          {/* <section className="custom-section pure-section normal-p grey-bg tree-bg dark-canvas">
            <div className="section-logo">
              <img src={imageUrl("logo.webp")} alt="" />
            </div>
            <p className="section-title light-title text-uppercase">
              {t("titles.want_invest")}
            </p>
            <p className="normal-p light-p pure-section-text">
              {t("descs.want_invest")}
            </p>
            <div className="d-flex justify-content-center mt-4 pt-2">
              <Link to={`${user ? "/invest" : "/registration"}`}>
                <button className="lp-btn"> {t("buttons.invest_now")}</button>
              </Link>
            </div>
          </section> */}

          {/* <section className="custom-section grid-section normal-p grey-bg">
            <p className="section-title text-uppercase dark-text">
              {t("titles.invest_franchise")}
            </p>
            <Container>
              <Row className="justify-content-center">
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <div className="lp-invest-img">
                      <img src={imageUrl("signup-form.png")} alt="" />
                    </div>
                    <p className="">{t("labels.invest_franchise_1")}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <div className="lp-invest-img">
                      <img src={imageUrl("find-store.png")} alt="" />
                    </div>
                    <p className="">{t("labels.invest_franchise_2")}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <div className="lp-invest-img">
                      <img src={imageUrl("fill-form.png")} alt="" />
                    </div>
                    <p className="">{t("labels.invest_franchise_3")}</p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <div className="lp-invest-img">
                      <img src={imageUrl("buy-stocks.png")} alt="" />
                    </div>
                    <p className="">{t("labels.invest_franchise_4")}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <div className="lp-invest-img">
                      <img src={imageUrl("become-investor.png")} alt="" />
                    </div>
                    <p className="">{t("labels.invest_franchise_5")}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}

          {/* <section className="custom-section grid-section2 normal-p text-center">
            <p className="section-title text-uppercase dark-text">
              {t("titles.booming_industry")}
            </p>
            <Container>
              <Row className="justify-content-center">
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <p className="large-p">773,603</p>
                    <p className="">{t("labels.booming_industry_1")}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <p className="large-p">7.6 {t("units.million")}</p>
                    <p className="">{t("labels.booming_industry_2")}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <p className="large-p">$787.5 {t("units.billion")}</p>
                    <p className="">{t("labels.booming_industry_3")}</p>
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <p className="large-p">100+</p>
                    <p className="">{t("labels.booming_industry_4")}</p>
                  </div>
                </Col>
                <Col md={4}>
                  <div className="lp-invest-i-wrapper">
                    <p className="large-p">4,300+</p>
                    <p className="">{t("labels.booming_industry_5")}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section> */}
          <section className="custom-section accordion-section normal-p text-center pb-5">
            <p className="section-title text-uppercase dark-text">
              {t("titles.javatimes_work")}
            </p>
            <Container>
              <Row className="justify-content-center">
                <Col md={6}>
                  <div className="target-img">
                    <img src={imageUrl("target.png")} alt="" />
                  </div>
                </Col>
                <Col md={6} className="accordion-wrapper">
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        {t("labels.javatimes_work_1")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {t("descs.javatimes_work_1")}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        {t("labels.javatimes_work_2")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {t("descs.javatimes_work_2")}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>
                        {t("labels.javatimes_work_3")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {t("descs.javatimes_work_3")}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        {t("labels.javatimes_work_4")}
                      </Accordion.Header>
                      <Accordion.Body>
                        {t("descs.javatimes_work_4")}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="custom-section testimonial-section grey-bg">
            <p className="section-title text-uppercase dark-text">
              TESTIMONIALS
            </p>
            <Container>
              <Slider {...settings_2}>
                {testimonials.map((testimonial) => {
                  return (
                    <>
                      <TestimonialCard
                        author={testimonial.author}
                        img={testimonial.img}
                        desc={testimonial.desc}
                      />
                    </>
                  );
                })}
              </Slider>
            </Container>
          </section>

          <section className="custom-section get-started-section">
            <Container>
              <Row className="align-items-center">
                <Col md="6" className="pb-3 pb-md-0">
                  <div className="get-started-body">
                    <p className="section-title text-uppercase dark-text  text-start">
                      Join Thousands of Investors
                    </p>
                    <p className="normal-p">
                      It's your turn to build your dream portfolio.
                    </p>
                    <a className="lp-btn normal-p" href="https://republic.com/">
                      Get Started
                    </a>
                    <div className="text-muted mt-3">
                      <small>
                        Investments are risky and illiquid, and may result in
                        total loss of capital. <a href="#">learn more.</a>
                      </small>
                    </div>
                  </div>
                </Col>
                <Col md="6">
                  <Row className="align-items-center pt-3 pt-md-0 g-3">
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-1.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-2.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-3.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-4.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-5.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-6.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-7.jpg")} alt="" />
                      </div>
                    </Col>
                    <Col className="col-6" sm={4} md={3}>
                      <div className="get-started-img">
                        <img src={imageUrl("get-started-8.jpg")} alt="" />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>


          <section className="custom-section grey-bg text-center team-section">
            <Container>
              <p className="section-title">Who are we? Meet our team!</p>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
              <Slider {...settings}>
                <TeamCard
                  img={"placeholder.png"}
                  name={"Tom Simpson"}
                  title={"CEO"}
                  desc={
                    "A quick brown fox jumps over the lazy dog. A quick brown fox jumps over the lazy dog. "
                  }
                />

                <TeamCard
                  img={"placeholder.png"}
                  name={"Tom Simpson"}
                  title={"CEO"}
                  desc={
                    "A quick brown fox jumps over the lazy dog. A quick brown fox jumps over the lazy dog. "
                  }
                />

                <TeamCard
                  img={"placeholder.png"}
                  name={"Tom Simpson"}
                  title={"CEO"}
                  desc={
                    "A quick brown fox jumps over the lazy dog. A quick brown fox jumps over the lazy dog. "
                  }
                />
                <TeamCard
                  img={"placeholder.png"}
                  name={"Tom Simpson"}
                  title={"CEO"}
                  desc={
                    "A quick brown fox jumps over the lazy dog. A quick brown fox jumps over the lazy dog. "
                  }
                />
              </Slider>
            </Container>
          </section>
          


          <section className="clip-section py-5">
            <p className="section-title red-underline">CEO's Message</p>
            <Container>
              <div className="ceo-message-box">
                <RiDoubleQuotesL className="quote-icon" />
                <p>
                  Dear valued client, <br />
                  <br />
                  We invite you to take part in shaping the future by investing
                  in JTC Mexico. As one of the leading specialty coffee
                  companies in the industry, we are excited to offer the
                  opportunity for individuals like yourself to invest in our
                  vision of expanding to 300 stores. With your investment, we
                  can continue to bring our high-quality products and
                  exceptional customer service to new communities, making JTC
                  Mexico a household name.
                  <br /> <br />
                  Please note that this investment opportunity is only available
                  to individuals 18 years or older.
                  <br /> <br />
                  We look forward to working with you and thank you for
                  considering JTC Mexico as your investment choice.
                  <br /> <br />
                  Sincerely, Antonio Leite
                </p>
              </div>
            </Container>
            <div className="triangle"></div>
          </section>
          <section className="grey-bg py-5">
            <Container>
            <p className="section-title">
              <span className="me-3"><MdOutlineAnnouncement /></span>
              Notice to Potential Investors</p>

            <dl>
              <dd>
                Please be advised that the information provided on this website
                regarding investment opportunities in Java Times Caffe is for
                general informational purposes only and should not be considered
                investment advice. Any decision to invest in the business should
                be based on your own due diligence and in consultation with a
                financial professional. Java Times Caffe and its management team
                do not provide investment advice and do not make any
                representations or warranties as to the accuracy or completeness
                of any information on this website or any linked site.
              </dd>
              <dd>
                Investing in a startup or small business carries inherent risks,
                and potential investors should be aware that they may lose some
                or all of their investment. We encourage you to consult with a
                financial advisor before making any investment decisions. "
              </dd>
            </dl>
            </Container>
          </section>

          <section className="custom-section text-section normal-p">
            <Container>
              <Row>
                <Col md={7}>
                  <p className="section-title dark-text text-start">
                    {t("titles.passive_invest")}
                  </p>
                  <div className="text-lowercase  text-start">
                    <p className="normal-p">✓ {t("labels.passive_invest_1")}</p>
                    <p className="normal-p">✓ {t("labels.passive_invest_2")}</p>
                    <p className="normal-p">✓ {t("labels.passive_invest_3")}</p>
                    <p className="normal-p">✓ {t("labels.passive_invest_4")}</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
