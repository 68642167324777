import {createSlice} from '@reduxjs/toolkit';
import reduxApis from "../apis";
import {toast} from "react-toastify";

const adminSlice = createSlice({
    name: 'Admin',
    initialState: {
        loading: false,
        adminUser: null,
        allUser: [],
        user: null,
        userSpecific: null,
        store: null,
        allStore: [],
        error: null,
    },
    reducers: {
        adminLogout: (state, action) => {
            state.adminUser = null
        }
    },
    extraReducers: {
        [reduxApis.adminLogin.pending]: (state, action) => {
            state.loading = true;
        },
        [reduxApis.adminLogin.fulfilled]: (state, action) => {
            toast.success(action.payload.data.message)
            state.loading = false;
            state.adminUser = action.payload.data.user;
        },
        [reduxApis.adminLogin.rejected]: (state, action) => {
            toast.error(action.error.message);
            state.loading = false;
            state.error = action.error;
        },
    },
});

export const {adminLogout} = adminSlice.actions;
export default adminSlice.reducer;
