export const blogs = [
    {
      title: "New on JTC: Investing in Javatimes Caffe",
      img: "traction.png",
      desc: "Java Times Caffé has its origins more than 15 years ago, as an organization dedicated to the service of the coffee industry in the United States of North America.",
      author: "Antonio Liete",
      time: "2 days",
    },
    {
      title: "How to measure a company's traction",
      img: "blog-2.png",
      desc: "It’s the third and final part of our series on evaluating startups! Today, let’s talk traction, progress, and other indicators of success.",
      author: "Antonio Liete",
      time: "5 days",
    },
    {
      title: "How to assess a startup founder",
      img: "blog-3.jpg",
      desc: "We’re back with Week 2 of our series on evaluating deals! Today, let’s talk about some of the things VCs and angel investors like to see in a founding team.",
      author: "Antonio Liete",
      time: "Today",
    },
    {
      title: "How crypto will drive the rise of greentech",
      img: "blog-4.jpg",
      desc: "Happy Earth Day! In this post, we'll debunk the crypto unsustainability myth once and for all.",
      author: "Antonio Liete",
      time: "Yesterday",
    },
    {
      title: "How to evaluate the market",
      img: "blog-5.jpg",
      desc: "Welcome to Week 1 of our three-part series on evaluating deals! Today, we'll cover some of the key things VCs and angel investors look at when assessing markets.",
      author: "Antonio Liete",
      time: "2 days",
    },
  ];
  
  export const testimonials = [
    {
      author: "Kevin Harrington",
      desc: "I invested because this is a huge opportunity. During the pandemic, the supply chain completely broke down. This is an innovative and tech-forward solution towards rebuilding a crucial part of the chain.",
      img: "investor-1.jpg",
    },
    {
      author: "Chamillionaire",
      desc: "Fleeting won the $100k pitch competition I did with E40 and we invested in them because they have real traction, a great team, a worthy mission, and most importantly a leader that has real domain expertise and vision that knows how to execute. almost 3 years ago",
      img: "investor-2.png",
    },
    {
      author: "Jon Najarian ",
      desc: "I love the design of the shoes and the fact that they are 110% sustainable. There are lots of cool investments out there, but not many that change an industry as dramatically as AERA. I think we will see more companies pushing for sustainable production and I applaud each that actually gets it right, like AERA.",
      img: "investor-3.jpg",
    },
  ];
  