import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {Box, Container, Grid, Paper,} from "@mui/material";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import {Header} from "components";
import useTranslation from "hooks/useTranslation";
import _ from "lodash"

const mdTheme = createTheme();

const Wallet = () => {
    const {adminUser} = useSelector((store) => store.admin);
    const {wallet} = useSelector((store) => store.wallet);
    const storeData = useSelector((store) => store.store);

    const walletData = _.get(adminUser, "wallet", {share: 0, balance: 0})

    const {t} = useTranslation();



    return (
        <ThemeProvider theme={mdTheme}>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <div>
                            <Header/>
                            <Container maxWidth="lg" sx={{mt: 0, mb: 4}}>
                                <h3 className="dashboard-title">{t("titles.wallet")}</h3>
                                <Grid container className="mt-4" justifyContent={"center"}>
                                    {/* small boxes */}
                                    {
                                        adminUser
                                            ? <>
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Paper
                                                        sx={{
                                                            p: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                        className="dashboard-box"
                                                    >
                                                        <h3 className="text-center">{t("labels.wallet_2")}</h3>
                                                        <h4>{adminUser ? walletData?.shares : wallet?.shares} </h4>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Paper
                                                        sx={{
                                                            p: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                        className="dashboard-box"
                                                    >
                                                        <h3 className="text-center">{t("labels.balance")}</h3>
                                                        <h4>{adminUser ? walletData?.balance : wallet?.shares} </h4>
                                                    </Paper>
                                                </Grid>
                                            </>
                                            : <>
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Paper
                                                        sx={{
                                                            p: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                        className="dashboard-box"
                                                    >
                                                        <h3 className="text-center">{t("labels.wallet_1")}</h3>
                                                        <h4>{wallet?.amount}</h4>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Paper
                                                        sx={{
                                                            p: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                        className="dashboard-box"
                                                    >
                                                        <h3 className="text-center">{t("labels.wallet_2")}</h3>
                                                        <h4> {wallet?.shares || walletData?.shares}</h4>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Paper
                                                        sx={{
                                                            p: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                        className="dashboard-box"
                                                    >
                                                        <h3 className="text-center">{t("labels.wallet_3")}</h3>
                                                        <h4> {wallet?.dividend || 0}</h4>
                                                    </Paper>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                                    <Paper
                                                        sx={{
                                                            p: 2,
                                                            display: "flex",
                                                            flexDirection: "column",
                                                        }}
                                                        className="dashboard-box"
                                                    >
                                                        <h3 className="text-center">{t("labels.wallet_4")}</h3>
                                                        <h4>{storeData?.store?.dividend || 0}</h4>
                                                    </Paper>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </Container>
                        </div>
                    </Box>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default Wallet;
