import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { SmosOne, SmosTwo } from "../components/Images";
import { Fade } from "react-reveal";
import useTranslation from "hooks/useTranslation";
function WeAreJava() {
  const { language, setLanguage, t } = useTranslation();

  return (
    <>
      <section>
        <Container fluid>
          <Row className="col-without-padding">
            <Col lg={6} className="about-card-order-1">
              <Fade left>
                <div className="position-relative">
                  <img src={SmosOne} className="card-lg-smos-img" />
                </div>
              </Fade>
            </Col>
            <Col lg={6} className="about-card-order-2">
              <Fade right>
                <div className="card-lg">
                  <h3 className="card-title">{t("titles.we_are_java_1")}</h3>
                  <p>
                    {t("descs.we_are_java_1")}
                    <br />
                    <br />
                    {t("descs.we_are_java_1_2")}
                  </p>
                </div>
              </Fade>
            </Col>
            <Col lg={6} className="about-card-order-4">
              <Fade left>
                <div className="card-lg">
                  <h3 className="card-title">{t("titles.we_are_java_2")}</h3>

                  <p>{t("descs.we_are_java_2")}</p>

                  <h3 className="card-title">{t("titles.we_are_java_3")}</h3>
                  <p>{t("descs.we_are_java_3")}</p>
                </div>
              </Fade>
            </Col>
            <Col lg={6} className="about-card-order-3">
              <Fade right>
                <img src={SmosTwo} />
              </Fade>
            </Col>
          </Row>
          <Container>
            <Fade right>
              <div className="row py-5">
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">{t("titles.we_are_java_4")}</h3>

                  <p>{t("descs.we_are_java_4")}</p>
                </div>
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">{t("titles.we_are_java_5")}</h3>

                  <p>{t("descs.we_are_java_5")}</p>
                </div>
                <div className="col-md-6 pure-card">
                  <h3 className="card-title">{t("titles.we_are_java_6")}</h3>

                  <p>{t("descs.we_are_java_6")}</p>
                </div>
              </div>
            </Fade>
          </Container>
        </Container>
      </section>
    </>
  );
}

export default WeAreJava;
