import React from "react";
import {CircularProgress} from "@mui/material";
import AdminNewSidebar from "./admin/AdminNewSidebar";

const Loader = () => {
    return (
        <>
            <AdminNewSidebar/>
            <div className="w-100 min-vh-100 d-flex justify-content-center align-items-center">
                <CircularProgress color="error"/>
            </div>
        </>
    );
};

export default Loader;
