import {useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import reduxApis from "../redux/apis";
import {Header, Sidebar} from "components";
import useTranslation from "hooks/useTranslation";

const AdminLogin = () => {
    const [formData, setFormData] = useState({email: "", password: ""});
    const [loginBtn, setLoginBtn] = useState(false);
    const {t} = useTranslation();


    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onChangeHandler = (e) => {
        const {id, value} = e.target;
        setFormData((prevState) => ({...prevState, [id]: value}));
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        try {
            setLoginBtn(true);
            const res = await dispatch(reduxApis.adminLogin(formData))
            setLoginBtn(false);
            if (res?.payload) {
                setTimeout(() => {
                    navigate("/admin/dashboard")
                }, 1000)
            }

        } catch (error) {
            setLoginBtn(false);
            console.log('ADMIN LOGIN ERROR', error);
            const message = error.response.data.message;
            toast.error(message);
        }
    };

    return (
        <div className="landing-main">
            <Sidebar/>
            <div className="landing-page landing-main-page custom-scroll">
                <Header/>
                <Container>
                    <Row>
                        <Col>
                            <h3 className="section-title dark-text ">{t("titles.login_admin")}</h3>

                        </Col>
                    </Row>
                    <Form onSubmit={onSubmitHandler}>
                        <Row>
                            <Col md={4}/>

                            <Col md={4}>

                                <Form.Group className="mb-3" controlId="email">
                                    <Form.Control
                                        type="email"
                                        placeholder={t("placeholders.email")}
                                        onChange={onChangeHandler}
                                        value={formData.email}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="password">
                                    <Form.Control
                                        type="password"
                                        placeholder={t("placeholders.password")}
                                        onChange={onChangeHandler}
                                        value={formData.password}
                                        required
                                    />
                                </Form.Group>

                                <Button
                                    variant="outline-danger"
                                    className="w-100 mb-5"
                                    type="submit"
                                    disabled={loginBtn}
                                >
                                    {t("buttons.login")}

                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {/* <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      /> */}
                </Container>
            </div>
        </div>
    );
};

export default AdminLogin;
