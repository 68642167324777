import React, {useEffect} from "react";
import {Route, Routes, useLocation,} from "react-router-dom";
import {
    AdminLogin,
    BlogPage,
    Contact,
    InvestNow,
    InvestorForm,
    LegalEntityInvestorForm,
    Login,
    NotFound,
    Offices,
    Registration,
    UserInvest,
    WeAreJava
} from "./screens";

// import { initialState, reducer } from '../src/reducer/UseReducer';
import {CheckoutForm, ProtectedRoute, ScrollToTop} from "components";
import DashboardContent from "screens/UserDashboard/Dashboard";
// import UserDetail from "./screens/UserDashboard/UserDetail";
import UserDetails from "./components/admin/userDetails";
import UpdateStore from "./components/admin/UpdateStore";
import Wallet from "./screens/UserDashboard/Wallet";
import CreateAdmin from "./components/admin/CreateAdmin";
import {AddUpdateStore, AddUser, AdminDashboard, Dividend, Stores, Transactions, Users,} from "screens/Admin";
import {RegistrationForm} from "components/forms";
import {LandingPage} from "screens";
import {useDispatch, useSelector} from "react-redux";
import {setUser} from "redux/slices/userSlice";
import apis from "services";
import Language from "./components/Language";

const App = () => {
    const path = ["/"]
    const location = useLocation();
    const dispatch = useDispatch();
    const {user} = useSelector((store) => store.user);
    const fetchUser = async () => {
        if (!user?.isVerified) {
            console.log("first")
            const res = await apis.getProfile(user?.id);
            if (res.data.status) {
                dispatch(setUser({id: res.data.user?._id, ...res.data.user}))
            }
        }
    }
    useEffect(() => {
        (async () => {
            if (user)
                await fetchUser()
        })();
    }, [location.pathname]);


    return (
        <>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={<Language/>}>
                    <Route path="/:lang">
                        <Route index element={<LandingPage/>}/>
                        <Route path="blog" element={<BlogPage/>}/>
                        <Route path="invest" element={<UserInvest/>}/>
                        <Route path="we-are-java" element={<WeAreJava/>}/>
                        <Route path="offices" element={<Offices/>}/>
                        <Route path="contact" element={<Contact/>}/>
                        <Route path="admin-login" element={<AdminLogin/>}/>
                        <Route path="login" element={<Login/>}/>
                        <Route path="invest-now" element={<InvestNow/>}/>
                        <Route path="legal-entity-investor" element={<LegalEntityInvestorForm/>}/>
                        <Route path="registration" element={<RegistrationForm/>}/>
                        <Route path="investment-registration" element={<Registration/>}/>
                        <Route path="admin/master/YWRtaW4=" element={<CreateAdmin/>}/>

                        {/*NOTE:: Admin Private Routes here*/}
                        <Route path='admin/' element={<ProtectedRoute isAdmin/>}>
                            <Route path="dashboard" element={<AdminDashboard/>}/>
                            <Route path="users" element={<Users/>}/>
                            <Route path="user-details/:id" element={<UserDetails/>}/>
                            <Route path="stores" element={<Stores/>}/>
                            <Route path="dividend" element={<Dividend/>}/>
                            <Route path="stores/add/" element={<AddUpdateStore/>}/>
                            <Route path="stores/add/:id" element={<AddUpdateStore/>}/>
                            <Route path="store/update/:id" element={<UpdateStore/>}/>
                            <Route path="users/add" element={<AddUser/>}/>
                            <Route path="transactions" element={<Transactions/>}/>
                            <Route path="wallet" element={<Wallet/>}/>
                            <Route path="investor" element={<InvestorForm/>}/>
                        </Route>

                        {/*NOTE:: User Private Routes here*/}
                        <Route exact path='user/' element={<ProtectedRoute/>}>
                            <Route path='dashboard' element={<DashboardContent/>}/>
                            <Route path="wallet" element={<Wallet/>}/>
                            <Route path="checkout" element={<CheckoutForm/>}/>
                        </Route>

                    </Route>
                </Route>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
};

export default App
