/**
 @param stores
 @param methods
 */
import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import {
    DeleteOutline,
    ImageOutlined,
    RemoveModeratorOutlined,
    VerifiedUserOutlined,
    VisibilityOutlined
} from "@mui/icons-material";
import useTranslation from "hooks/useTranslation";

const StoresTable = (stores = [], methods = {}, openImagePreview) => {
    const {handleEnableStore, handleDisableStore, navigate, handleDeleteStore} = methods;
    const { language, setLanguage, t } = useTranslation();


    const columns = [
        {field: 'id', headerName: t("fields.id"), width: 150, hide: true,},
        {field: 'title', headerName: t("fields.title"), width: 150},
        {field: 'description', headerName: t("fields.description"), width: 150},
        {field: 'dividend', headerName: t("fields.dividend"), width: 150},
        {field: 'state', headerName: t("fields.state"), width: 150},
        {field: 'city', headerName: t("fields.city"), width: 150},
        {
            field: 'address', headerName: t("fields.address"), width: 150,
            renderCell: (param) => <Tooltip title={param.value}><Typography noWrap>{param.value}</Typography></Tooltip>
        }
        ,
        {field: 'image', headerName: t("fields.image"), width: 150, renderCell: (param) => <IconButton onClick={() => openImagePreview(param.value)}><ImageOutlined/></IconButton>},
        {field: 'active', headerName: t("fields.active"), width: 150},
        {
            field: 'actions', headerName: t("fields.actions"), width: 150, renderCell: ({row}) => {
                const isActive = row.active === 'Yes';
                const id = row._id;

                // console.log(row, 'ROW')
                return (
                    <Grid container>
                        <Grid item>
                            <Tooltip title='View & Update' arrow>
                                <IconButton size='small' onClick={() => navigate(`add/${id}`)}>
                                    <VisibilityOutlined/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            {
                                isActive
                                    ? (<Tooltip title='Disable Store' arrow>
                                        <IconButton size='small' onClick={() => handleDisableStore(id)}>
                                            <RemoveModeratorOutlined/>
                                        </IconButton>
                                    </Tooltip>)
                                    : (<Tooltip title='Enable Store' arrow>
                                        <IconButton size='small' onClick={() => handleEnableStore(id)}>
                                            <VerifiedUserOutlined/>
                                        </IconButton>
                                    </Tooltip>)
                            }
                        </Grid>
                        <Grid item>
                            <Tooltip title='Delete Store' arrow>
                                <IconButton size='small' onClick={() => handleDeleteStore(id)}>
                                    <DeleteOutline/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )
            }
        },
    ];

    console.log(stores);

    const rows = stores.length === 0
        ? []
        : [
            ...stores.map((store, index) => ({
                id: index,
                title: store.title,
                description: store.description,
                dividend: store.dividend,
                state: store.location.state,
                city: store.location.city,
                address: store.location.address,
                active: store.active ? 'Yes' : 'NO',
                image: store.image,
                _id: store._id
            }))
        ];


    return {columns, rows}
}


export default StoresTable
