import React, {Fragment, useEffect, useState} from "react";
import {Link as LinkR} from "react-router-dom";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import MailIcon from "@mui/icons-material/Mail";
import {useDispatch, useSelector} from "react-redux";
import apis from "services";
import {Paid} from "@mui/icons-material";
import AdminNewSidebar from "components/admin/AdminNewSidebar";
import imageUrl from "utils/imageUrl";
import {Header} from "components";
import DashboardChart from "components/DashboardChart";
import useTranslation from "hooks/useTranslation";
import utils from "../../utils";
import {ADMIN_TYPES} from "../../assets/constants";

export const mainListItems = (
    <Fragment>
        <LinkR to={"/admin/dashboard"} style={{textDecoration: "none"}}>
            <ListItemButton
                // autoFocus={true}
                className="justify-content-center mt-3 active"
            >
                <ListItemIcon>
                    <DashboardIcon sx={{color: "#fff"}}/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Dashboard"/>
            </ListItemButton>
        </LinkR>

        <LinkR to={"/admin/users"} style={{textDecoration: "none"}}>
            <ListItemButton className="justify-content-center mt-3">
                <ListItemIcon>
                    <PeopleIcon sx={{color: "#fff"}}/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Users"/>
            </ListItemButton>
        </LinkR>

        <LinkR to={"/admin/stores"} style={{textDecoration: "none"}}>
            <ListItemButton className="justify-content-center mt-3">
                <ListItemIcon sx={{color: "#fff"}}>
                    <MailIcon/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Stores"/>
            </ListItemButton>
        </LinkR>

        <LinkR to={"/admin/transactions"} style={{textDecoration: "none"}}>
            <ListItemButton className="justify-content-center mt-3">
                <ListItemIcon sx={{color: "#fff"}}>
                    <Paid/>
                </ListItemIcon>
                <ListItemText className="text-white" primary="Transactions"/>
            </ListItemButton>
        </LinkR>
    </Fragment>
);

const mdTheme = createTheme();

const AdminDashboard = () => {
    const adminData = useSelector((store) => store.admin);
    const adminDataLogin = adminData?.admin?.data?.admin;
    const {t} = useTranslation();

    console.log(utils.checkAdminType(), '###########')

    const [anchorEl, setAnchorEl] = useState(null);

    const [TotalUsers, setTotalUsers] = useState("loading ...");
    // let { paramId } = useParams();
    // if (paramId === "dashboard") {
    //   let header = (document.getElementById("custom-header").style.display =
    //     "none");
    // }

    const dispatch = useDispatch();

    const [totalUsersCount, setTotalUsersCount] = useState(0);
    const [totalStoresCount, setTotalStoresCount] = useState(0);

    const fetchTotalStoresCountCount = async () => {
        const res = await apis.getTotalStoresCount();
        if (res?.data?.status) setTotalStoresCount(res?.data?.totalStores);
    };

    const fetchTotalUsersCount = async () => {
        const res = await apis.getTotalUsersCount();
        if (res?.data?.status) setTotalUsersCount(res?.data?.totalUsersCount);
    };


//Charts Api
    const [charData, setChartData] = useState();

    const fetchChartData = async () => {
        const res = await apis.getChartData();
        setChartData(res?.data?.yearly_data);
    };


    //Analytic
    const [data, setData] = useState([]);

    const fetchData = async () => {
        const res = await apis.getTotalRecord();
        setData(res?.data);
    };
    useEffect(() => {
        (async () => {
            await fetchData();
            await fetchChartData();
        })();
    }, []);


    useEffect(() => {
        //Promise All for simultaneous requests
        (async () => {
            await Promise.all([fetchTotalStoresCountCount(), fetchTotalUsersCount()]);
        })();
    }, []);

    return (
        <ThemeProvider theme={mdTheme}>
            <Box className="dashboard-main">
                <AdminNewSidebar/>
                <div className="dashboard-section-main">
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            height: "100vh",
                            overflow: "auto",
                        }}
                        className="custom-scroll"
                    >
                        <Header/>
                        <Container maxWidth="lg" sx={{mt: 0, mb: 4}}>
                            <h3 className="dashboard-title">{t("titles.dashboard")}</h3>
                            <Grid container className="mt-4 pt-1 db-container">
                                <div className="custom-box row">
                                    {/* USER BOX */}
                                    {
                                        utils.checkAdminType() === ADMIN_TYPES.ADMIN
                                            ? <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Paper className="dashboard-box">
                                                    <div className="text">
                                                        <h3 className="">{t("labels.admin_dashboard_1")}</h3>
                                                        <h4 className="">{totalUsersCount}</h4>
                                                    </div>
                                                    <img src={imageUrl("d_users.png")}/>
                                                </Paper>
                                            </Grid>
                                            : <></>
                                    }

                                    {/* TOTAL SHARES BOX */}
                                    {
                                        utils.checkAdminType() === ADMIN_TYPES.ADMIN
                                            ? <Grid item xs={12} sm={6} md={4} lg={3}>
                                                <Paper className="dashboard-box">
                                                    <div className="text">
                                                        <h3 className="">{t("labels.admin_dashboard_2")}</h3>
                                                        <h4 className="">{data?.TOTAL_SHARES || 0}</h4>
                                                    </div>
                                                    <img src={imageUrl("d_shares.png")}/>
                                                </Paper>
                                            </Grid>
                                            : <></>
                                    }

                                    {/*"TOTAL AVAILABLE SHARES"*/}
                                    {utils.checkAdminType() === ADMIN_TYPES.ADMIN
                                        ? <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <Paper className="dashboard-box">
                                                <div className="text">
                                                    <h3 className="">{t("labels.admin_dashboard_3")}</h3>
                                                    <h4 className="">{data?.totalAvailableShares || 0}</h4>
                                                </div>
                                                <img src={imageUrl("d_available_shares.png")}/>
                                            </Paper>
                                        </Grid>
                                        : <></>
                                    }


                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Paper className="dashboard-box">
                                            <div className="text">
                                                <h3 className="">{t("labels.admin_dashboard_4")}</h3>
                                                <h4 className="">{totalStoresCount || 0}</h4>
                                            </div>
                                            <img src={imageUrl("d_stores.png")}/>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Paper className="dashboard-box">
                                            <div className="text">
                                                <h3 className="">Total Funded Store Amount</h3>
                                                <h4 className="">{data?.totalStoreFundedAmount || 0}</h4>
                                            </div>
                                            <img src={imageUrl("d_profit.png")}/>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Paper className="dashboard-box">
                                            <div className="text">
                                                <h3 className="">Share Per Price</h3>
                                                <h4 className="">{data?.TOTAL_PRICE_PER_SHARE || 0}</h4>
                                            </div>
                                            <img src={imageUrl("d_profit.png")}/>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Paper className="dashboard-box">
                                            <div className="text">
                                                <h3 className="">Total Investor</h3>
                                                <h4 className="">{data?.totalInvestor || 0}</h4>
                                            </div>
                                            <img src={imageUrl("d_users.png")}/>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <Paper className="dashboard-box">
                                            <div className="text">
                                                <h3 className="">{t("labels.admin_dashboard_5")}</h3>
                                                <h4 className="">0</h4>
                                            </div>
                                            <img src={imageUrl("d_profit.png")}/>
                                        </Paper>
                                    </Grid>

                                </div>
                                {/* Chart */}
                                <Grid item xs={12} md={12} lg={12} className="db-chart">
                                    <Paper>
                                        <div className="mt-5">
                                            <DashboardChart data={charData}/>
                                        </div>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Container>
                    </Box>
                </div>
            </Box>
        </ThemeProvider>
    );
};

export default AdminDashboard;
