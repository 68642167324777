import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { CardOne } from "../components/Images";
import { Bounce, Flip } from "react-reveal";
import apis from "../services";
import { toast } from "react-toastify";
import imageUrl from "utils/imageUrl";
import { Link } from "react-router-dom";
import useTranslation from "hooks/useTranslation";

const INITIAL_FORM_STATE = { name: "", email: "", message: "" };

function Contact() {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const { language, setLanguage, t } = useTranslation();


  const onChangeHandler = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const onSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      setLoader(true);
      const res = await apis.saveContact(formData);

      if (res.status === 201) {
        toast.success(res.data.message);
        setFormData(INITIAL_FORM_STATE);
      }

      setLoader(false);
    } catch (e) {
      toast.error("");
      setLoader(false);
    }
  };

  return (
    <>
      <section className="light-img-bg position-relative">
        <div className="head-box">
          <Link to="/">
            <img
              src={imageUrl("logo-icon.png")}
              alt=""
              className="head-box-logo"
            />
          </Link>
        </div>
        <Container>
          <div className="custom-box cutting-box">
            <h3 className="dashboard-title text-center fw-light">
              {t("titles.contact")}
            </h3>
            <form onSubmit={onSubmitHandler} className="mt-4">
              <Row className="mb-5">
                <Col md={7}>
                  <div className="contact-form">
                    <label htmlFor="name">{t("labels.name")}</label>
                    <input
                      className="form-control"
                      placeholder={t("placeholders.name")}
                      name="name"
                      onChange={onChangeHandler}
                      value={formData.name}
                      required
                      id="name"
                    />
                    <label htmlFor="email">{t("labels.email")}</label>

                    <input
                      className="form-control"
                      type="email"
                      placeholder={t("placeholders.email")}
                      name="email"
                      onChange={onChangeHandler}
                      value={formData.email}
                      required
                      id="email"
                    />
                    <label htmlFor="message">{t("labels.message")}</label>

                    <textarea
                      className="form-control"
                      placeholder={t("placeholders.message")}
                      name="message"
                      onChange={onChangeHandler}
                      value={formData.message}
                      rows={10}
                      id="message"
                    ></textarea>
                  </div>

                  <button
                    className="lp-btn full-btn p-2"
                    type="submit"
                    disabled={loader}
                  >
                    {loader ? t("buttons.submitting") : t("buttons.submit")}
                  </button>
                </Col>
                <Col md={5}>
                  <div className="contact-right">
                    <div className="">
                      <div className="info-item">
                        <img
                          src={imageUrl("address-icon.png")}
                          alt="icon_not_found"
                          className="icon"
                        />
                        <div className="info-item-body">
                          <p className="info-title">{t("labels.address")}:</p>
                          <p className="info-desc">
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Veritatis, natus!
                          </p>
                        </div>
                      </div>
                      <div className="info-item">
                        <img
                          src={imageUrl("phone-icon.png")}
                          alt="icon_not_found"
                          className="icon"
                        />
                        <div className="info-item-body">
                          <p className="info-title">{t("labels.number")}:</p>
                          <p className="info-desc">+00 0000000000</p>
                        </div>
                      </div>
                      <div className="info-item">
                        <img
                          src={imageUrl("email-icon.png")}
                          alt="icon_not_found"
                          className="icon"
                        />
                        <div className="info-item-body">
                          <p className="info-title">{t("labels.email")}:</p>
                          <p className="info-desc">placeholder@example.com</p>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="social-icons">
                        <Link>
                          <i class="fa-brands fa-facebook-f"></i>
                        </Link>
                        <Link>
                          <i class="fa-brands fa-twitter"></i>
                        </Link>
                        <Link>
                          <i class="fa-brands fa-instagram"></i>
                        </Link>
                        <Link>
                          <i class="fa-brands fa-linkedin-in"></i>
                        </Link>
                      </div>
                      <img
                        src={imageUrl("map-image.png")}
                        alt=""
                        className="contact-map"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
      </section>
    </>
  );
}

export default Contact;
