import { useEffect, useMemo, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { STATIC_STORE_DATA, COUNTRY_LIST } from "../assets/constants";
import apis from "../services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useTranslation from "hooks/useTranslation";

const CheckoutForm = () => {
  // const [prices, setPrices] = useState({ user: 0, owner: 0, total: 0 });
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { sharePerPrice } = STATIC_STORE_DATA;
  const { language, setLanguage, t } = useTranslation();

  const { user } = useSelector((store) => store.user);

  console.log({state}, "*********************************");

  useEffect(() => {
    if (!state) {
      navigate("/404");
    }
  }, []);

  const products = state
    ? [
        state,
        /*{name: 'Product Name', description: 'Brief description', price: 12},
        {name: 'Product Name', description: 'Brief description', price: 12},
        {name: 'Product Name', description: 'Brief description', price: 12},
        {name: 'Product Name', description: 'Brief description', price: 12},*/
      ]
    : [];

  /*useEffect(() => {
        calculateValues(products)
    }, []);

    const calculateValues = (products) => {
        let total = products.reduce((sum,{price}) => {
            sum+=price;
            return sum;
        },0);

        const user = total * 0.7;
        const owner = total * 0.3;
        total-= - owner;

        setPrices({user,owner,total});
    };*/

  const totalPrice = useMemo(() => {
    const storePrice = products.reduce((sum, store) => {
      sum += store?.price || 0;
      return sum;
    }, 0);

    return storePrice * state?.sharePerPrice;
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const formData = Object.fromEntries(form);
    console.log(formData);

    setLoader(true);

    try {
      const { data } = await apis.invest(state.id, {
        userId: user.id,
        shareAmount: state.price,
        sharePerPrice: state.sharePerPrice,
        totalShares: state.totalShares,
      });
      if (data.status) {
        toast.success(data?.message);
        document.getElementById("billing-form").reset();
        navigate("/user/dashboard");
      } else {
        toast.error(data?.message);
      }
    } catch (e) {
      console.log(e);
      toast.error("Some error has occurred");
    }
  };
  return (
    <Container>
      <div className="row g-5 mt-2 mb-5">
        <div className="col-md-5 col-lg-4 order-md-last">
          <h4 className="d-flex justify-content-between align-items-center mb-3">
            <span className="text-danger">{t("titles.cart")}</span>
            <span className="badge bg-danger rounded-pill">
              {products.length}
            </span>
          </h4>
          <ul className="list-group mb-3">
            {products.length > 0 &&
              products.map(({ title, description, price }) => {
                return (
                  <li className="list-group-item d-flex justify-content-between lh-sm">
                    <div>
                      <h6 className="my-0">{title}</h6>
                      <small className="text-muted">{description}</small>
                    </div>
                    <span className="text-muted">
                      ({price}) x ({state.sharePerPrice}) = $
                      {price * state.sharePerPrice}
                    </span>
                  </li>
                );
              })}

            {/*<li className="list-group-item d-flex justify-content-between bg-light">
                            <div className="text-success">
                                <h6 className="my-0">Promo code</h6>
                                <small>EXAMPLECODE</small>
                            </div>
                            <span className="text-success">−$5</span>
                        </li>*/}
            <li className="list-group-item d-flex justify-content-between">
              <span>Total (USD)</span>
              <strong>$ {totalPrice}</strong>
            </li>
          </ul>

          {/*<form className="card p-2">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Promo code"/>
                            <button type="submit" className="btn btn-secondary">Redeem</button>
                        </div>
                    </form>*/}
        </div>
        <div className="col-md-7 col-lg-8">
          <h4 className="mb-3">{t("titles.checkout")}</h4>
          <form
            id="billing-form"
            className="needs-validation"
            onSubmit={submitHandler}
          >
            <div className="row g-3">
              <div className="col-sm-6">
                <label htmlFor="firstName" className="form-label">
                {t("labels.first_name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  required
                />
                <div className="invalid-feedback">
                  {t("messages.first_name")}
                </div>
              </div>

              <div className="col-sm-6">
                <label htmlFor="lastName" className="form-label">
                {t("labels.last_name")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  required
                />
                <div className="invalid-feedback">
                {t("messages.last_name")}
                </div>
              </div>

              <div className="col-12">
                <label htmlFor="email" className="form-label">
                {t("labels.email")}
                  {/*<span className="text-muted">(Optional)</span>*/}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="you@example.com"
                  required
                />
                <div className="invalid-feedback" />
                {t("messages.email")}
                
              </div>
              {/*</div>*/}

              <div className="col-12">
                <label htmlFor="address" className="form-label">
                {t("labels.address")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  name="address"
                  placeholder="1234 Main St"
                  required
                />
                <div className="invalid-feedback">
                {t("messages.shipping_address")}
                  
                </div>
              </div>

              {/*<div className="col-12">
                                <label htmlFor="address2" className="form-label">Address 2 <span
                                    className="text-muted">(Optional)</span></label>
                                <input type="text" className="form-control" id="address2" placeholder="Apartment or suite"/>
                            </div>*/}

              <div className="col-md-5">
                <label htmlFor="country" className="form-label">
                {t("labels.country")}
                </label>
                <select
                  className="form-select"
                  id="country"
                  name="country"
                  required
                >
                  <option value="">{t("buttons.choose")}</option>
                  {COUNTRY_LIST.map((item) => {
                    return (
                      <>
                        {" "}
                        <option>{item}</option>
                      </>
                    );
                  })}
                </select>
                <div className="invalid-feedback">
                  Please select a valid country.
                </div>
              </div>

              <div className="col-md-4">
                <label htmlFor="state" className="form-label">
                {t("labels.state")}
                </label>
                <select
                  className="form-select"
                  id="state"
                  name="state"
                  required
                >
                  <option value="">{t("buttons.choose")}</option>
                  <option value="LND">London, City of</option>
                  <option value="ABE">Aberdeen City</option>
                  <option value="ABD">Aberdeenshire</option>
                  <option value="ANS">Angus</option>
                  <option value="AGB">Argyll and Bute</option>
                  <option value="CLK">Clackmannanshire</option>
                  <option value="DGY">Dumfries and Galloway</option>
                  <option value="DND">Dundee City</option>
                  <option value="EAY">East Ayrshire</option>
                  <option value="EDU">East Dunbartonshire</option>
                  <option value="ELN">East Lothian</option>
                  <option value="ERW">East Renfrewshire</option>
                  <option value="EDH">Edinburgh, City of</option>
                  <option value="ELS">Eilean Siar</option>
                  <option value="FAL">Falkirk</option>
                  <option value="FIF">Fife</option>
                  <option value="GLG">Glasgow City</option>
                  <option value="HLD">Highland</option>
                  <option value="IVC">Inverclyde</option>
                  <option value="MLN">Midlothian</option>
                  <option value="MRY">Moray</option>
                  <option value="NAY">North Ayrshire</option>
                  <option value="NLK">North Lanarkshire</option>
                  <option value="ORK">Orkney Islands</option>
                  <option value="PKN">Perth and Kinross</option>
                  <option value="RFW">Renfrewshire</option>
                  <option value="SCB">Scottish Borders</option>
                  <option value="ZET">Shetland Islands</option>
                  <option value="SAY">South Ayrshire</option>
                  <option value="SLK">South Lanarkshire</option>
                  <option value="STG">Stirling</option>
                  <option value="WDU">West Dunbartonshire</option>
                  <option value="WLN">West Lothian</option>
                  <option value="ANN">Antrim and Newtownabbey</option>
                  <option value="AND">Ards and North Down</option>
                  <option value="ABC">
                    Armagh City, Banbridge and Craigavon
                  </option>
                  <option value="BFS">Belfast City</option>
                  <option value="CCG">Causeway Coast and Glens</option>
                  <option value="DRS">Derry and Strabane</option>
                  <option value="FMO">Fermanagh and Omagh</option>
                  <option value="LBC">Lisburn and Castlereagh</option>
                  <option value="MEA">Mid and East Antrim</option>
                  <option value="MUL">Mid-Ulster</option>
                  <option value="NMD">Newry, Mourne and Down</option>
                  <option value="BDG">Barking and Dagenham</option>
                  <option value="BNE">Barnet</option>
                  <option value="BEX">Bexley</option>
                  <option value="BEN">Brent</option>
                  <option value="BRY">Bromley</option>
                  <option value="CMD">Camden</option>
                  <option value="CRY">Croydon</option>
                  <option value="EAL">Ealing</option>
                  <option value="ENF">Enfield</option>
                  <option value="GRE">Greenwich</option>
                  <option value="HCK">Hackney</option>
                  <option value="HMF">Hammersmith and Fulham</option>
                  <option value="HRY">Haringey</option>
                  <option value="HRW">Harrow</option>
                  <option value="HAV">Havering</option>
                  <option value="HIL">Hillingdon</option>
                  <option value="HNS">Hounslow</option>
                  <option value="ISL">Islington</option>
                  <option value="KEC">Kensington and Chelsea</option>
                  <option value="KTT">Kingston upon Thames</option>
                  <option value="LBH">Lambeth</option>
                  <option value="LEW">Lewisham</option>
                  <option value="MRT">Merton</option>
                  <option value="NWM">Newham</option>
                  <option value="RDB">Redbridge</option>
                  <option value="RIC">Richmond upon Thames</option>
                  <option value="SWK">Southwark</option>
                  <option value="STN">Sutton</option>
                  <option value="TWH">Tower Hamlets</option>
                  <option value="WFT">Waltham Forest</option>
                  <option value="WND">Wandsworth</option>
                  <option value="WSM">Westminster</option>
                  <option value="BNS">Barnsley</option>
                  <option value="BIR">Birmingham</option>
                  <option value="BOL">Bolton</option>
                  <option value="BRD">Bradford</option>
                  <option value="BUR">Bury</option>
                  <option value="CLD">Calderdale</option>
                  <option value="COV">Coventry</option>
                  <option value="DNC">Doncaster</option>
                  <option value="DUD">Dudley</option>
                  <option value="GAT">Gateshead</option>
                  <option value="KIR">Kirklees</option>
                  <option value="KWL">Knowsley</option>
                  <option value="LDS">Leeds</option>
                  <option value="LIV">Liverpool</option>
                  <option value="MAN">Manchester</option>
                  <option value="NET">Newcastle upon Tyne</option>
                  <option value="NTY">North Tyneside</option>
                  <option value="OLD">Oldham</option>
                  <option value="RCH">Rochdale</option>
                  <option value="ROT">Rotherham</option>
                  <option value="SLF">Salford</option>
                  <option value="SAW">Sandwell</option>
                  <option value="SFT">Sefton</option>
                  <option value="SHF">Sheffield</option>
                  <option value="SOL">Solihull</option>
                  <option value="STY">South Tyneside</option>
                  <option value="SHN">St. Helens</option>
                  <option value="SKP">Stockport</option>
                  <option value="SND">Sunderland</option>
                  <option value="TAM">Tameside</option>
                  <option value="TRF">Trafford</option>
                  <option value="WKF">Wakefield</option>
                  <option value="WLL">Walsall</option>
                  <option value="WGN">Wigan</option>
                  <option value="WRL">Wirral</option>
                  <option value="WLV">Wolverhampton</option>
                  <option value="BKM">Buckinghamshire</option>
                  <option value="CAM">Cambridgeshire</option>
                  <option value="CMA">Cumbria</option>
                  <option value="DBY">Derbyshire</option>
                  <option value="DEV">Devon</option>
                  <option value="DOR">Dorset</option>
                  <option value="ESX">East Sussex</option>
                  <option value="ESS">Essex</option>
                  <option value="GLS">Gloucestershire</option>
                  <option value="HAM">Hampshire</option>
                  <option value="HRT">Hertfordshire</option>
                  <option value="KEN">Kent</option>
                  <option value="LAN">Lancashire</option>
                  <option value="LEC">Leicestershire</option>
                  <option value="LIN">Lincolnshire</option>
                  <option value="NFK">Norfolk</option>
                  <option value="NYK">North Yorkshire</option>
                  <option value="NTH">Northamptonshire</option>
                  <option value="NTT">Nottinghamshire</option>
                  <option value="OXF">Oxfordshire</option>
                  <option value="SOM">Somerset</option>
                  <option value="STS">Staffordshire</option>
                  <option value="SFK">Suffolk</option>
                  <option value="SRY">Surrey</option>
                  <option value="WAR">Warwickshire</option>
                  <option value="WSX">West Sussex</option>
                  <option value="WOR">Worcestershire</option>
                  <option value="BAS">Bath and North East Somerset</option>
                  <option value="BDF">Bedford</option>
                  <option value="BBD">Blackburn with Darwen</option>
                  <option value="BPL">Blackpool</option>
                  <option value="BGW">Blaenau Gwent</option>
                  <option value="BCP">
                    Bournemouth, Christchurch and Poole
                  </option>
                  <option value="BRC">Bracknell Forest</option>
                  <option value="BGE">
                    Bridgend [Pen-y-bont ar Ogwr GB-POG]
                  </option>
                  <option value="BNH">Brighton and Hove</option>
                  <option value="BST">Bristol, City of</option>
                  <option value="CAY">Caerphilly [Caerffili GB-CAF]</option>
                  <option value="CRF">Cardiff [Caerdydd GB-CRD]</option>
                  <option value="CMN">
                    Carmarthenshire [Sir Gaerfyrddin GB-GFY]
                  </option>
                  <option value="CBF">Central Bedfordshire</option>
                  <option value="CGN">Ceredigion [Sir Ceredigion]</option>
                  <option value="CHE">Cheshire East</option>
                  <option value="CHW">Cheshire West and Chester</option>
                  <option value="CWY">Conwy</option>
                  <option value="CON">Cornwall</option>
                  <option value="DAL">Darlington</option>
                  <option value="DEN">
                    Denbighshire [Sir Ddinbych GB-DDB]
                  </option>
                  <option value="DER">Derby</option>
                  <option value="DUR">Durham, County</option>
                  <option value="ERY">East Riding of Yorkshire</option>
                  <option value="FLN">Flintshire [Sir y Fflint GB-FFL]</option>
                  <option value="GWN">Gwynedd</option>
                  <option value="HAL">Halton</option>
                  <option value="HPL">Hartlepool</option>
                  <option value="HEF">Herefordshire</option>
                  <option value="AGY">
                    Isle of Anglesey [Sir Ynys Môn GB-YNM]
                  </option>
                  <option value="IOW">Isle of Wight</option>
                  <option value="IOS">Isles of Scilly</option>
                  <option value="KHL">Kingston upon Hull</option>
                  <option value="LCE">Leicester</option>
                  <option value="LUT">Luton</option>
                  <option value="MDW">Medway</option>
                  <option value="MTY">
                    Merthyr Tydfil [Merthyr Tudful GB-MTU]
                  </option>
                  <option value="MDB">Middlesbrough</option>
                  <option value="MIK">Milton Keynes</option>
                  <option value="MON">Monmouthshire [Sir Fynwy GB-FYN]</option>
                  <option value="NTL">
                    Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]
                  </option>
                  <option value="NWP">Newport [Casnewydd GB-CNW]</option>
                  <option value="NEL">North East Lincolnshire</option>
                  <option value="NLN">North Lincolnshire</option>
                  <option value="NSM">North Somerset</option>
                  <option value="NBL">Northumberland</option>
                  <option value="NGM">Nottingham</option>
                  <option value="PEM">Pembrokeshire [Sir Benfro GB-BNF]</option>
                  <option value="PTE">Peterborough</option>
                  <option value="PLY">Plymouth</option>
                  <option value="POR">Portsmouth</option>
                  <option value="POW">Powys</option>
                  <option value="RDG">Reading</option>
                  <option value="RCC">Redcar and Cleveland</option>
                  <option value="RCT">
                    Rhondda Cynon Taff [Rhondda CynonTaf]
                  </option>
                  <option value="RUT">Rutland</option>
                  <option value="SHR">Shropshire</option>
                  <option value="SLG">Slough</option>
                  <option value="SGC">South Gloucestershire</option>
                  <option value="STH">Southampton</option>
                  <option value="SOS">Southend-on-Sea</option>
                  <option value="STT">Stockton-on-Tees</option>
                  <option value="STE">Stoke-on-Trent</option>
                  <option value="SWA">Swansea [Abertawe GB-ATA]</option>
                  <option value="SWD">Swindon</option>
                  <option value="TFW">Telford and Wrekin</option>
                  <option value="THR">Thurrock</option>
                  <option value="TOB">Torbay</option>
                  <option value="TOF">Torfaen [Tor-faen]</option>
                  <option value="VGL">
                    Vale of Glamorgan, The [Bro Morgannwg GB-BMG]
                  </option>
                  <option value="WRT">Warrington</option>
                  <option value="WBK">West Berkshire</option>
                  <option value="WIL">Wiltshire</option>
                  <option value="WNM">Windsor and Maidenhead</option>
                  <option value="WOK">Wokingham</option>
                  <option value="WRX">Wrexham [Wrecsam GB-WRC]</option>
                  <option value="YOR">York</option>
                  <option value="LND">London, City of</option>
                  <option value="ABE">Aberdeen City</option>
                  <option value="ABD">Aberdeenshire</option>
                  <option value="ANS">Angus</option>
                  <option value="AGB">Argyll and Bute</option>
                  <option value="CLK">Clackmannanshire</option>
                  <option value="DGY">Dumfries and Galloway</option>
                  <option value="DND">Dundee City</option>
                  <option value="EAY">East Ayrshire</option>
                  <option value="EDU">East Dunbartonshire</option>
                  <option value="ELN">East Lothian</option>
                  <option value="ERW">East Renfrewshire</option>
                  <option value="EDH">Edinburgh, City of</option>
                  <option value="ELS">Eilean Siar</option>
                  <option value="FAL">Falkirk</option>
                  <option value="FIF">Fife</option>
                  <option value="GLG">Glasgow City</option>
                  <option value="HLD">Highland</option>
                  <option value="IVC">Inverclyde</option>
                  <option value="MLN">Midlothian</option>
                  <option value="MRY">Moray</option>
                  <option value="NAY">North Ayrshire</option>
                  <option value="NLK">North Lanarkshire</option>
                  <option value="ORK">Orkney Islands</option>
                  <option value="PKN">Perth and Kinross</option>
                  <option value="RFW">Renfrewshire</option>
                  <option value="SCB">Scottish Borders</option>
                  <option value="ZET">Shetland Islands</option>
                  <option value="SAY">South Ayrshire</option>
                  <option value="SLK">South Lanarkshire</option>
                  <option value="STG">Stirling</option>
                  <option value="WDU">West Dunbartonshire</option>
                  <option value="WLN">West Lothian</option>
                  <option value="ANN">Antrim and Newtownabbey</option>
                  <option value="AND">Ards and North Down</option>
                  <option value="ABC">
                    Armagh City, Banbridge and Craigavon
                  </option>
                  <option value="BFS">Belfast City</option>
                  <option value="CCG">Causeway Coast and Glens</option>
                  <option value="DRS">Derry and Strabane</option>
                  <option value="FMO">Fermanagh and Omagh</option>
                  <option value="LBC">Lisburn and Castlereagh</option>
                  <option value="MEA">Mid and East Antrim</option>
                  <option value="MUL">Mid-Ulster</option>
                  <option value="NMD">Newry, Mourne and Down</option>
                  <option value="BDG">Barking and Dagenham</option>
                  <option value="BNE">Barnet</option>
                  <option value="BEX">Bexley</option>
                  <option value="BEN">Brent</option>
                  <option value="BRY">Bromley</option>
                  <option value="CMD">Camden</option>
                  <option value="CRY">Croydon</option>
                  <option value="EAL">Ealing</option>
                  <option value="ENF">Enfield</option>
                  <option value="GRE">Greenwich</option>
                  <option value="HCK">Hackney</option>
                  <option value="HMF">Hammersmith and Fulham</option>
                  <option value="HRY">Haringey</option>
                  <option value="HRW">Harrow</option>
                  <option value="HAV">Havering</option>
                  <option value="HIL">Hillingdon</option>
                  <option value="HNS">Hounslow</option>
                  <option value="ISL">Islington</option>
                  <option value="KEC">Kensington and Chelsea</option>
                  <option value="KTT">Kingston upon Thames</option>
                  <option value="LBH">Lambeth</option>
                  <option value="LEW">Lewisham</option>
                  <option value="MRT">Merton</option>
                  <option value="NWM">Newham</option>
                  <option value="RDB">Redbridge</option>
                  <option value="RIC">Richmond upon Thames</option>
                  <option value="SWK">Southwark</option>
                  <option value="STN">Sutton</option>
                  <option value="TWH">Tower Hamlets</option>
                  <option value="WFT">Waltham Forest</option>
                  <option value="WND">Wandsworth</option>
                  <option value="WSM">Westminster</option>
                  <option value="BNS">Barnsley</option>
                  <option value="BIR">Birmingham</option>
                  <option value="BOL">Bolton</option>
                  <option value="BRD">Bradford</option>
                  <option value="BUR">Bury</option>
                  <option value="CLD">Calderdale</option>
                  <option value="COV">Coventry</option>
                  <option value="DNC">Doncaster</option>
                  <option value="DUD">Dudley</option>
                  <option value="GAT">Gateshead</option>
                  <option value="KIR">Kirklees</option>
                  <option value="KWL">Knowsley</option>
                  <option value="LDS">Leeds</option>
                  <option value="LIV">Liverpool</option>
                  <option value="MAN">Manchester</option>
                  <option value="NET">Newcastle upon Tyne</option>
                  <option value="NTY">North Tyneside</option>
                  <option value="OLD">Oldham</option>
                  <option value="RCH">Rochdale</option>
                  <option value="ROT">Rotherham</option>
                  <option value="SLF">Salford</option>
                  <option value="SAW">Sandwell</option>
                  <option value="SFT">Sefton</option>
                  <option value="SHF">Sheffield</option>
                  <option value="SOL">Solihull</option>
                  <option value="STY">South Tyneside</option>
                  <option value="SHN">St. Helens</option>
                  <option value="SKP">Stockport</option>
                  <option value="SND">Sunderland</option>
                  <option value="TAM">Tameside</option>
                  <option value="TRF">Trafford</option>
                  <option value="WKF">Wakefield</option>
                  <option value="WLL">Walsall</option>
                  <option value="WGN">Wigan</option>
                  <option value="WRL">Wirral</option>
                  <option value="WLV">Wolverhampton</option>
                  <option value="BKM">Buckinghamshire</option>
                  <option value="CAM">Cambridgeshire</option>
                  <option value="CMA">Cumbria</option>
                  <option value="DBY">Derbyshire</option>
                  <option value="DEV">Devon</option>
                  <option value="DOR">Dorset</option>
                  <option value="ESX">East Sussex</option>
                  <option value="ESS">Essex</option>
                  <option value="GLS">Gloucestershire</option>
                  <option value="HAM">Hampshire</option>
                  <option value="HRT">Hertfordshire</option>
                  <option value="KEN">Kent</option>
                  <option value="LAN">Lancashire</option>
                  <option value="LEC">Leicestershire</option>
                  <option value="LIN">Lincolnshire</option>
                  <option value="NFK">Norfolk</option>
                  <option value="NYK">North Yorkshire</option>
                  <option value="NTH">Northamptonshire</option>
                  <option value="NTT">Nottinghamshire</option>
                  <option value="OXF">Oxfordshire</option>
                  <option value="SOM">Somerset</option>
                  <option value="STS">Staffordshire</option>
                  <option value="SFK">Suffolk</option>
                  <option value="SRY">Surrey</option>
                  <option value="WAR">Warwickshire</option>
                  <option value="WSX">West Sussex</option>
                  <option value="WOR">Worcestershire</option>
                  <option value="BAS">Bath and North East Somerset</option>
                  <option value="BDF">Bedford</option>
                  <option value="BBD">Blackburn with Darwen</option>
                  <option value="BPL">Blackpool</option>
                  <option value="BGW">Blaenau Gwent</option>
                  <option value="BCP">
                    Bournemouth, Christchurch and Poole
                  </option>
                  <option value="BRC">Bracknell Forest</option>
                  <option value="BGE">
                    Bridgend [Pen-y-bont ar Ogwr GB-POG]
                  </option>
                  <option value="BNH">Brighton and Hove</option>
                  <option value="BST">Bristol, City of</option>
                  <option value="CAY">Caerphilly [Caerffili GB-CAF]</option>
                  <option value="CRF">Cardiff [Caerdydd GB-CRD]</option>
                  <option value="CMN">
                    Carmarthenshire [Sir Gaerfyrddin GB-GFY]
                  </option>
                  <option value="CBF">Central Bedfordshire</option>
                  <option value="CGN">Ceredigion [Sir Ceredigion]</option>
                  <option value="CHE">Cheshire East</option>
                  <option value="CHW">Cheshire West and Chester</option>
                  <option value="CWY">Conwy</option>
                  <option value="CON">Cornwall</option>
                  <option value="DAL">Darlington</option>
                  <option value="DEN">
                    Denbighshire [Sir Ddinbych GB-DDB]
                  </option>
                  <option value="DER">Derby</option>
                  <option value="DUR">Durham, County</option>
                  <option value="ERY">East Riding of Yorkshire</option>
                  <option value="FLN">Flintshire [Sir y Fflint GB-FFL]</option>
                  <option value="GWN">Gwynedd</option>
                  <option value="HAL">Halton</option>
                  <option value="HPL">Hartlepool</option>
                  <option value="HEF">Herefordshire</option>
                  <option value="AGY">
                    Isle of Anglesey [Sir Ynys Môn GB-YNM]
                  </option>
                  <option value="IOW">Isle of Wight</option>
                  <option value="IOS">Isles of Scilly</option>
                  <option value="KHL">Kingston upon Hull</option>
                  <option value="LCE">Leicester</option>
                  <option value="LUT">Luton</option>
                  <option value="MDW">Medway</option>
                  <option value="MTY">
                    Merthyr Tydfil [Merthyr Tudful GB-MTU]
                  </option>
                  <option value="MDB">Middlesbrough</option>
                  <option value="MIK">Milton Keynes</option>
                  <option value="MON">Monmouthshire [Sir Fynwy GB-FYN]</option>
                  <option value="NTL">
                    Neath Port Talbot [Castell-nedd Port Talbot GB-CTL]
                  </option>
                  <option value="NWP">Newport [Casnewydd GB-CNW]</option>
                  <option value="NEL">North East Lincolnshire</option>
                  <option value="NLN">North Lincolnshire</option>
                  <option value="NSM">North Somerset</option>
                  <option value="NBL">Northumberland</option>
                  <option value="NGM">Nottingham</option>
                  <option value="PEM">Pembrokeshire [Sir Benfro GB-BNF]</option>
                  <option value="PTE">Peterborough</option>
                  <option value="PLY">Plymouth</option>
                  <option value="POR">Portsmouth</option>
                  <option value="POW">Powys</option>
                  <option value="RDG">Reading</option>
                  <option value="RCC">Redcar and Cleveland</option>
                  <option value="RCT">
                    Rhondda Cynon Taff [Rhondda CynonTaf]
                  </option>
                  <option value="RUT">Rutland</option>
                  <option value="SHR">Shropshire</option>
                  <option value="SLG">Slough</option>
                  <option value="SGC">South Gloucestershire</option>
                  <option value="STH">Southampton</option>
                  <option value="SOS">Southend-on-Sea</option>
                  <option value="STT">Stockton-on-Tees</option>
                  <option value="STE">Stoke-on-Trent</option>
                  <option value="SWA">Swansea [Abertawe GB-ATA]</option>
                  <option value="SWD">Swindon</option>
                  <option value="TFW">Telford and Wrekin</option>
                  <option value="THR">Thurrock</option>
                  <option value="TOB">Torbay</option>
                  <option value="TOF">Torfaen [Tor-faen]</option>
                  <option value="VGL">
                    Vale of Glamorgan, The [Bro Morgannwg GB-BMG]
                  </option>
                  <option value="WRT">Warrington</option>
                  <option value="WBK">West Berkshire</option>
                  <option value="WIL">Wiltshire</option>
                  <option value="WNM">Windsor and Maidenhead</option>
                  <option value="WOK">Wokingham</option>
                  <option value="WRX">Wrexham [Wrecsam GB-WRC]</option>
                  <option value="YOR">York</option>
                </select>
                <div className="invalid-feedback">
                  Please provide a valid state.
                </div>
              </div>

              <div className="col-md-3">
                <label htmlFor="zip" className="form-label">
                {t("labels.zip")}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="zip"
                  name="state"
                  required
                />
                <div className="invalid-feedback">Zip code required.</div>
              </div>
            </div>

            {/* <hr className="my-4"/> */}

            {/*<div className="form-check">
                            <input type="checkbox" className="form-check-input" id="same-address"/>
                            <label className="form-check-label" htmlFor="same-address">Shipping address is the same
                                as my billing address</label>
                        </div>

                        <div className="form-check">
                            <input type="checkbox" className="form-check-input" id="save-info"/>
                            <label className="form-check-label" htmlFor="save-info">Save this information for next
                                time</label>
                        </div>*/}

            {/*<hr className="my-4"/>*/}

            {/* <h4 className="mb-3">Payment</h4>

                        <div className="my-3">
                            <div className="form-check">
                                <input id="credit" name="paymentMethod" type="radio" className="form-check-input"
                                        value="Credit Card" />
                                <label className="form-check-label" htmlFor="credit">Credit card</label>
                            </div>
                            <div className="form-check">
                                <input id="debit" name="paymentMethod" type="radio" className="form-check-input"
                                       value="Debit card"/>
                                <label className="form-check-label" htmlFor="debit">Debit card</label>
                            </div>
                            <div className="form-check">
                                <input id="paypal" name="paymentMethod" type="radio" className="form-check-input"
                                       value="PayPal"/>
                                <label className="form-check-label" htmlFor="paypal">PayPal</label>
                            </div>
                        </div>

                        <div className="row gy-3">
                            <div className="col-md-6">
                                <label htmlFor="cc-name" className="form-label">Name on card</label>
                                <input type="text" className="form-control" id="cc-name" name="cc-name" required/>
                                <small className="text-muted">Full name as displayed on card</small>
                                <div className="invalid-feedback">
                                    Name on card is required
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label htmlFor="cc-number" className="form-label">Credit card number</label>
                                <input type="number" className="form-control" id="cc-number" name="cc-number" required/>
                                <div className="invalid-feedback">
                                    Credit card number is required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cc-expiration" className="form-label">Expiration</label>
                                <input type="text" className="form-control" id="cc-expiration" name="cc-expiration" required/>
                                <div className="invalid-feedback">
                                    Expiration date required
                                </div>
                            </div>

                            <div className="col-md-3">
                                <label htmlFor="cc-cvv" className="form-label">CVV</label>
                                <input type="number" className="form-control" id="cc-cvv" name="cc-cvv" required/>
                                <div className="invalid-feedback">
                                    Security code required
                                </div>
                            </div>
                        </div>

                        <hr className="my-4"/> */}

            <button className="lp-btn full-btn mt-4 p-2" type="submit">
              {t("buttons.checkout")}
            </button>
          </form>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutForm;
