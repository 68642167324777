import { useEffect, useState } from "react";
import reduxApis from "redux/apis";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormText,
  Row,
} from "react-bootstrap";
import { Header, Sidebar } from "components";
import { Box } from "@mui/material";
import useTranslation from "hooks/useTranslation";

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const { language, setLanguage, t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { user, loading } = useSelector((store) => store.user);

  if (user) {
    navigate(state ? state.from : "/user/dashboard");
  }

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, []);
  const onChangeHandler = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      await dispatch(reduxApis.userLogin(formData));
    } catch (error) {
      console.log("Error in Login", error);
    }
  };

  return (
    <div className="landing-main">
      <Sidebar />
      <div className="landing-page landing-main-page custom-scroll dashboard-section-main">
        <Header />
        <Container>
          <Form onSubmit={onSubmitHandler} className="px-4">
            <Row className=" mt-4">
              <Col md={2} lg={3} />

              <Col md={8} lg={6} className="custom-box login">
                {/*{error.status && (
                            <Alert
                                variant={error.variant}
                                onClose={() =>
                                    setError((prevState) => ({...prevState, status: false}))
                                }
                                dismissible
                            >
                                Password Invalid
                            </Alert>
                        )}*/}
                <h3 className="dashboard-title text-center">{t("titles.login")}</h3>

                <Form.Group className="mb-3 mt-4" controlId="email">
                  <Form.Control
                    type="email"
                    placeholder={t("placeholders.email")}
                    onChange={onChangeHandler}
                    value={formData.email}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="password">
                  <Form.Control
                    type="password"
                    placeholder={t("placeholders.password")}
                    onChange={onChangeHandler}
                    value={formData.password}
                    required
                  />
                  <FormText>
                    <Link to="/admin-login">{t("messages.login_admin")}</Link>
                  </FormText>
                </Form.Group>
                <div className="center-wrapper">
                  <Button className="lp-btn" type="submit" disabled={loading}>
                    {loading ? t("buttons.logging_in") : t("buttons.login")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default Login;
