import React from "react";

const PitchCard = () => {
  return (
    <>
      <div className="pitch-card-wrapper">
        <article className="pitch-card white">
          <div className="pitch-card-front">
            <p className="fw-bold text-uppercase muted-text">Lorem, ipsum.</p>
            <div className="pitch-card-item">
              <p className="flex-p">
                <span>Invest:</span>$00.00
              </p>
              <p className="flex-p">
                <span>Earn:</span>$00.00
              </p>
            </div>
            <div className="pitch-card-item">
              <p className="flex-p">
                <span>Invest:</span>$00.00
              </p>
              <p className="flex-p">
                <span>Earn:</span>$00.00
              </p>
            </div>
            <div className="pitch-card-item">
              <p className="flex-p">
                <span>Invest:</span>$00.00
              </p>
              <p className="flex-p">
                <span>Earn:</span>$00.00
              </p>
            </div>
          </div>
          <div className="pitch-card-back">
          <p className="fw-bold text-uppercase muted-text">Lorem, ipsum.</p>
            <p className="white-muted text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident explicabo porro quia debitis iste dolor!</p>
          </div>
        </article>
      </div>
    </>
  );
};

export default PitchCard;
