import useTranslation from "hooks/useTranslation";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import imageUrl from "utils/imageUrl";
import {logout} from "../redux/slices/userSlice";
import {AiOutlineSearch} from 'react-icons/ai'

const MainHeader = () => {
    const navigate = useNavigate();
    const {user} = useSelector((store) => store.user);
    const admin = useSelector((store) => store.admin);
    const dispatch = useDispatch();
    const {language, setLanguage, t} = useTranslation();

    const onChangeLanguage = (lng) => {
        setLanguage(lng);
        navigate(`/${lng}`)
    };

    return (
        <>
            <div className="main-header">
                <div className="header-left">
                    <Link to={'/'}>
                        <div className="main-logo">
                            <img src={imageUrl("logo.webp")} alt=""/>
                        </div>
                    </Link>
                    <div className="header-line"></div>
                    <Link className="search-icon">
                        <AiOutlineSearch/>
                    </Link>
                    <Link to={'/invest'} className="main-header-link">
                        Invest
                    </Link>
                    <Link className="main-header-link">
                        Learn
                    </Link>
                </div>
                <div className="button-group">
                    <div className="language-btn">
                        <button
                            className={`${language === "en" ? "active-lng" : ""}`}
                            onClick={() => onChangeLanguage("en")}
                        >
                            EN
                        </button>
                        <button
                            className={`${language === "sp" ? "active-lng" : ""}`}
                            onClick={() => onChangeLanguage("sp")}
                        >
                            ES
                        </button>
                    </div>
                    {user ? (
                        <>
                            <button onClick={() => navigate("/user/dashboard")} className="lp-btn">
                                {t("buttons.dashboard")}
                            </button>
                        </>
                    ) : admin?.adminUser ? (
                        <>
                            <button
                                onClick={() => navigate("/admin/dashboard")}
                                className="lp-btn"
                            >
                                {t("buttons.dashboard")}
                            </button>
                        </>
                    ) : (
                        <>
                            {" "}
                            <button
                                onClick={() => navigate("/login")}
                                className="simple-btn login"
                            >
                                {t("buttons.login")}
                            </button>
                            <button
                                onClick={() => navigate("/registration")}
                                className="lp-btn"
                            >
                                {t("buttons.signup")}
                            </button>
                        </>
                    )}

                    {user && (
                        <>
                            <Link to="/login">
                                <button
                                    onClick={() => {
                                        dispatch(logout());
                                        // close()
                                    }}
                                    className="lp-btn ms-2"
                                >
                                    {" "}
                                    {/* <img src={Logout} alt='logout'/>  */}
                                    <i class="fa-solid fa-right-from-bracket"></i>{" "}
                                    {t("buttons.logout")}
                                </button>
                            </Link>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default MainHeader;
