import apis from "services";
import {toast} from "react-toastify";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Button from "@mui/material/Button";
import {setUser} from "redux/slices/userSlice";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField} from "@mui/material";
import useTranslation from "hooks/useTranslation";


const UserProfileModal = ({status, close}) => {
    const { language, setLanguage, t } = useTranslation();

    const dispatch = useDispatch();

    const [userData, setUserData] = useState(null);
    const [readOnly, setReadOnly] = useState(true);

    const {user} = useSelector((store) =>store.user);

    useEffect(() => {
        if(!userData) {
            setUserData(user);
        }
    },[user, userData])



    const toggleButton = async () => {
        if (readOnly) {
            setReadOnly(false);
        } else {
            console.log(userData);
            const res = await apis.updateUserProfile(user.id, userData);
            if (res.data.status) {
                toast.success(res.data.message);
                 //dispatch(setUser(res.data.user))  
                 setReadOnly(true);
            }
        }
    }

    const onChangeHandler = (event) => {
        const {id, value} = event.target;

        setUserData({...userData, [id]: value});
    }

    return (
        <Dialog open={status} onClose={close} maxWidth='md'>
            <DialogTitle>{t("titles.edit_profile")}</DialogTitle>
            <DialogContent>
                <Grid container direction='column' gap={2} my={2}>
                    <Grid item>
                        <TextField
                            id="name"
                            value={userData?.name || ''}
                            onChange={onChangeHandler}
                            placeholder={t("placeholders.name")}
                            label={"name"}
                            disabled={readOnly}

                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="lastName"
                            value={userData?.lastName || ''}
                            onChange={onChangeHandler}
                            label={t("labels.last_name")}
                            disabled={readOnly}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            id="phone"
                            value={userData?.phone || ''}
                            onChange={onChangeHandler}
                            label={t("labels.phone")}
                            disabled={readOnly}
                        />
                    </Grid>
                </Grid>
                <DialogActions>
                    <Grid item container gap={1}>
                        <Button
                            onClick={toggleButton}
                            variant="outlined"
                            style={{borderColor: "#E11F1C", color: '#E11F1C'}}
                            fullWidth
                        >
                            {readOnly ? t("buttons.edit") : t("buttons.update")}
                        </Button>
                        <Button
                            onClick={close}
                            style={{backgroundColor: "#E11F1C", color: "#ffff"}}
                            fullWidth>{t("buttons.close")}
                        </Button>
                    </Grid>
                </DialogActions>
            </DialogContent>
        </Dialog>
    )
}


export default UserProfileModal;
