import React, {StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';
import './assets/fontawesome/css/all.css';
import App from './App';
import config from 'react-reveal/globals';
import {Provider} from 'react-redux';
import store from './redux/store';
import {Slide, ToastContainer} from 'react-toastify';
import {PersistGate} from 'redux-persist/integration/react'
import {persistStore} from 'redux-persist'
import { BrowserRouter } from 'react-router-dom';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

config({ssrFadeout: true});
let persistor = persistStore(store);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>  
            <App/>
             </BrowserRouter>
             <ToastContainer
                    limit={3}
                    draggable={false}
                    theme='colored'
                    position="top-center" autoClose={2000}
                    // transition={Slide}
                    newestOnTop
                />
            </PersistGate>
        </Provider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
