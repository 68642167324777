import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import apis from "services";
import DealTerm from "./DealTerm";
import OpenHeader from "./OpenHeader";
import OpenPitch from "./OpenPitch";
import PitchCard from "./PitchCard";

const OpenSection = () => {
  const [tab, setTab] = useState(0);
  const [subTab, setSubTab] = useState('problem')

  
  const [data, setData] = useState([]);

  const fetchData = async () => {
    const res = await apis.getRecentUserInvest();
    setData(res?.data?.recentUser);
  };
  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col md={2} className="col-2">
            <OpenHeader tab={tab} setTab={setTab} subTab={subTab} setSubTab={setSubTab} />
          </Col>
          <Col md={10}>
            <Row>

           <Col md={8}>
           <OpenPitch subTab={subTab} data={data}/>
            </Col> 
           <Col md={4}>
            <PitchCard />
            {/* <p className="section-title">Recent Investments</p>
            <div className="deal-term-wrapper">
              {data?.slice(0, 8).map((item, index) => {
                return (
                  <>
                    <DealTerm key={index} data={item} />
                  </>
                );
              })}
            </div> */}
          </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OpenSection;
