import axios from "axios";


const createBackendServer = (baseURL) => {
    const api = axios.create({
        baseURL,
        headers: {Accept: "application/json"},
        timeout: 60 * 1000,
    });

    //Interceptor
    api.interceptors.response.use(
        (response) => response,
        (error) => {
            const message = error?.response?.data?.message;
            error.message = message ?? error.message
            if(error?.response?.data?.errors)
                error.errors = error?.response?.data?.errors;
            return Promise.reject(error)
        })

    const headers = {
        "Content-Type": "multipart/form-data",
    };

    /*==========    INVESTORS APIS    ==========*/
    const investorRegistration = async (body) => api.post('/api/investors/investor', body,{headers});
    const legalInvestorRegistration = async (body) => api.post('/api/investors/legal_investor', body,{headers});

    /*==========    Admin USER APIS    ==========*/
    const getTotalUsersCount = async () => api.get('/api/users/count');
    const deleteUser = async (id) => api.delete(`/api/users/delete/${id}`);
    const approveUser = async (id) => api.get(`/api/invest/verify/${id}`);

    /*==========    Admin STORE APIS    ==========*/
    const disableStore = async (id) => await api.put(`/api/store/disable/${id}`)
    const enableStore = async (id) => await api.put(`/api/store/enable/${id}`)
    const getTotalStoresCount = async () =>await api.get('/api/store/count');

    const getAllUser = async () => api.get('/api/users/allUser');
    const getAllStores = async () => api.get("/api/store/");
    const getAllActiveStores = async () => api.get("/api/store/all");
    const getCountTotalShares = () => api.get('/api/store/countStores');//soon
    const getCountAvailableShares = () => api.get('/api/store/countStores');//soon

    const updateUserProfile = (id, body) =>
        api.put(`/api/users/profile/${id}`, body);

    const getWallet = (id) => api.get(`/api/wallet/${id}`);

    const getProfile = async id => api.get(`/api/users/profile/${id}`);
    const getStore = (id) => api.get(`/api/store/${id}`);
    const getAllStore = async () => await api.get('/api/store');
    const getTotalRecord = async () => await api.get('/api/users/countData');
    const getRecentUserInvest = async () => await api.get('/api/users/recentUserInvest');
    const getChartData = async () => await api.get('/api/users/analyticStore');
    
    
    const createSecretAdmin = async () => api.get('/api/users/createAdmin?master=fundraiser125%');

    /*==========    POST REQUESTS    ==========*/
    const authLogin = async body => api.post("/api/users/login", body)

    const saveContact = async (body) => api.post('/api/contact', body);
    const adminLogin = async (body) => api.post("/api/users/admin-login", body)
    const updateStore = async (body) => api.put(`/api/store`, body, {headers});

    const registration = (body) => api.post("/api/users", body, {headers});
    const legalForm = (body) =>
        api.post("/api/users/legal-Form", body, {headers});
    const investNow = (body) => api.post("/api/invest/investor/:id", body);
    const createStore = async (body) => api.post("/api/store", body, {headers});
    const deleteStores = async (id) => api.delete("api/store", {data: id});
    const invest = async (id,body) => api.post(`/api/invest/wallet/${id}`, body)
    const generateDividend = async (body) => api.post(`/api/invest/generate_dividend`,body);

    const getTransactions = async () => api.get('/api/transactions');

    //Returning all the API
    return {
        invest,
        getStore,
        legalForm,
        authLogin,
        investNow,
        getWallet,
        getProfile,
        getAllUser,
        deleteUser,
        adminLogin,
        getAllStore,
        approveUser,
        enableStore,
        createStore,
        updateStore,
        saveContact,
        getAllStores,
        deleteStores,
        disableStore,
        registration,
        getTransactions,
        generateDividend,
        createSecretAdmin,
        updateUserProfile,
        getTotalUsersCount,
        getAllActiveStores,
        getCountTotalShares,
        getTotalStoresCount,
        investorRegistration,
        getCountAvailableShares,
        legalInvestorRegistration,
        getTotalRecord,
        getRecentUserInvest,
        getChartData
    };
};

const apis = createBackendServer(process.env.REACT_APP_SERVER_URL);

export default apis;
