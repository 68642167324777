import useTranslation from "hooks/useTranslation";

const TransactionTable = (transactions = []) => {
    const { language, setLanguage, t } = useTranslation();


    const columns = [
        {field: 'id', headerName: 'Id', width: 150, hide: true,},
        {field: 'store', headerName: t("fields.store"), width: 150},
        {field: 'userName', headerName: t("fields.userName"), width: 150},
        {field: 'amountInvested', headerName: t("fields.amountInvested"), width: 150},
        {field: 'sharePrice', headerName: t("fields.sharePrice"), width: 150},
        {field: 'purchasedShare', headerName: t("fields.purchasedShare"), width: 150},
        {field: 'purchasedAt', headerName: t("fields.purchasedAt"), width: 150}
    
    ];

    const rows = transactions.length === 0
        ? []
        : [
            ...transactions.map((transaction,index) => ({
                id: index,
                store: transaction['storeName'],
                userName: transaction['userName'],
                amountInvested: transaction['amountInvested'],
                sharePrice: transaction['sharePrice'],
                purchasedShare: transaction['purchasedShare'],
                purchasedAt : new Date(transaction['purchasedAt']).toLocaleString()
           
            }))
        ];


    return {rows, columns}
}

export default TransactionTable
