import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import '../assets/css/header.css';
import {Logo, WalletCardIcon} from './Images';
import {useDispatch, useSelector} from 'react-redux';
import DashboardIcon from '@mui/icons-material/Dashboard';

import {logout} from "../redux/slices/userSlice";
import {adminLogout} from "redux/slices/adminSlice";
import {PersonOutlined} from "@mui/icons-material";
import UserProfileModal from "./modals/UserProfileModal";
import useTranslation from 'hooks/useTranslation';

export function BasicCard({name, lastName, phone, type, email, close, isVerified, toggleProfileModal, ...restProps}) {
    const {firstName, isAdmin} = restProps;
    const {t} = useTranslation();

    const dispatch = useDispatch();

    if (isAdmin)
        return (
            <Card sx={{minWidth: 275, padding: '20px 0'}} className="profile-card">
                <div className="text-center">
                    <IconButton color="inherit" variant="contained">
                        <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>{firstName.at(0)}</Avatar>
                    </IconButton>
                </div>
                <CardContent className="text-center">
                    <Typography variant="h5" component="div">
                        {firstName}
                    </Typography>
                    <Typography variant="p" component="div">
                        {email}
                    </Typography>
                </CardContent>
                <Link to={'/admin/dashboard'} style={{textDecoration: 'none'}}>
                    <div className="card-wallet d-flex my-1">
                        <div className='modal-li-flex'>
                            <DashboardIcon sx={{color: "#E60E10"}}/>
                            <Typography
                                variant="p"
                                component="span"
                                className="mx-3"
                                color="#E60E10"
                            >
                                {t("buttons.dashboard")}
                            </Typography>
                        </div>
                        <div>
                            <Typography variant="h6" component="span" color="#E60E10">
                                {/*{walletData?.wallet?.map((item) => (
                                <h3>{item?.balance}</h3>
                            ))}*/}
                            </Typography>
                        </div>
                    </div>

                </Link>

                {/*<Link to={'/dashboard'} style={{ textDecoration: 'none' }}>
                    <div className="card-wallet d-flex  my-3">
                         <img src={DashboardIcon} className="mb-2" />

                        <DashboardIcon sx={{ color: "#E60E10" }}/>
                        <Typography
                            variant="h6"
                            component="span"
                            className="mx-3"
                            color="#E60E10"
                        >
                            Dashboard
                        </Typography>
                    </div>
                </Link>*/}

                <CardActions className="justify-content-center">
                    <Link to="/login">
                        <button onClick={() => {
                            dispatch(adminLogout());
                            close();

                        }} className="lp-btn">
                            {/* <img src={Logout} alt='logout'/>  */}
                            <i class="fa-solid fa-right-from-bracket"></i> {t("buttons.logout")}
                        </button>
                    </Link>
                </CardActions>
            </Card>
        )

    return (
        <Card sx={{minWidth: 275, padding: '20px 0'}} className="light-bg">
            <div className="text-center">
                <IconButton color="inherit" variant="contained">
                    <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>{name.at(0)}</Avatar>
                </IconButton>
            </div>
            <CardContent className="text-center">
                <Typography variant="h5" component="div">
                    {name}
                </Typography>
                <Typography variant="p" component="div" fontSize={14}>
                    {email}
                </Typography>
            </CardContent>
            {
                (isVerified) && <>
                    <Link to={'/user/wallet'} style={{textDecoration: 'none'}}>
                        <div className="card-wallet d-flex my-1">
                            <div>
                                <img src={WalletCardIcon} className="mb-2" alt='wallet_card'/>
                                <Typography
                                    variant="p"
                                    component="span"
                                    className="mx-3"
                                    color="#E60E10"
                                >
                                    {t("buttons.wallet")}
                                </Typography>
                            </div>
                            <div>
                            </div>
                        </div>
                    </Link>

                    <div style={{textDecoration: 'none'}} role='button' onClick={() => {
                        close();
                        toggleProfileModal()
                    }
                    }>
                        <div className="card-wallet d-flex my-1">
                            <div>
                                <PersonOutlined sx={{color: "#E60E10"}}/>
                                <Typography
                                    variant="p"
                                    component="span"
                                    className="mx-3"
                                    color="#E60E10"
                                >
                                    {t("buttons.profile")}
                                </Typography>
                            </div>
                            <div>
                            </div>
                        </div>

                    </div>

                    <Link to={'/user/dashboard'} style={{textDecoration: 'none'}}>
                        <div className="card-wallet d-flex  my-1">
                            {/* <img src={DashboardIcon} className="mb-2" />
           */}
                            <DashboardIcon sx={{color: "#E60E10"}}/>
                            <Typography
                                variant="p"
                                component="span"
                                className="mx-3"
                                color="#E60E10"
                            >
                                {t("buttons.dashboard")}
                            </Typography>
                        </div>
                    </Link>
                </>
            }
            <CardActions className="justify-content-center">
                <Link to="/login">
                    <button onClick={() => {
                        dispatch(logout())
                        close()
                    }} className="lp-btn">
                        {' '}
                        {/* <img src={Logout} alt='logout'/>  */}
                        <i className="fa-solid fa-right-from-bracket"></i> {t("buttons.logout")}
                    </button>
                </Link>
            </CardActions>
        </Card>
    );
}

function Header() {
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [profileModal, setProfileModal] = useState(false);
    const {t} = useTranslation();


    const toggleProfileModal = () => setProfileModal(prevState => !prevState);

    const {user} = useSelector((store) => store.user);
    const {adminUser} = useSelector(store => store.admin);

    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const popOpen = Boolean(anchorEl);
    const id = popOpen ? 'simple-popover' : undefined;

    return (
        <>
            <header className="custom-header" id="custom-header">
                <div className="logo-section">
                    <Link to="/"> <img src={Logo} alt="logo" className='dashboard-logo'/> </Link>
                </div>

                <div
                    // className={
                    //     isShow ? 'header-main header-show' : 'header-main header-hide'
                    // }
                >

                    <ul className="header-body">
                        {
                            (() => {
                                if (user) {
                                    return (
                                        (
                                            <li>
                                                <IconButton
                                                    color="inherit"
                                                    variant="contained"
                                                    onClick={handleClick}
                                                >
                                                    <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>
                                                        {user.name.at(0)}
                                                    </Avatar>
                                                </IconButton>
                                                <Popover
                                                    // id={id}
                                                    open={popOpen}
                                                    anchorEl={anchorEl}
                                                    onClose={handleClose}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'right',
                                                    }}
                                                >
                                                    <BasicCard toggleProfileModal={toggleProfileModal}
                                                               close={handleClose} {...user}/>
                                                </Popover>
                                            </li>
                                        )
                                    )
                                } else if (adminUser) {
                                    return (
                                        <li>
                                            <IconButton
                                                color="inherit"
                                                aria-describedby={id}
                                                variant="contained"
                                                onClick={handleClick}
                                            >
                                                <Avatar sx={{bgcolor: '#F1C9C9', color: '#E60E10'}}>
                                                    {adminUser.firstName.at(0)}
                                                </Avatar>
                                            </IconButton>
                                            <Popover
                                                id={id}
                                                open={popOpen}
                                                anchorEl={anchorEl}
                                                onClose={handleClose}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <BasicCard toggleProfileModal={toggleProfileModal}
                                                           close={handleClose} {...adminUser} isAdmin/>
                                            </Popover>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li>
                                            {user ? <></> : <div className='flex-btns'>
                                                <button onClick={() => navigate('/login')} className="simple-btn login">
                                                    {t("buttons.login")}
                                                </button>
                                                <button onClick={() => navigate('/registration')} className="lp-btn">
                                                    {t("buttons.signup")}
                                                </button>
                                            </div>}
                                        </li>
                                    )
                                }
                            })()
                        }
                    </ul>
                    {/* <div className="header-bottom">

          </div> */}
                </div>
                {/* <i className="fa-solid fa-bars mobile-show" onClick={setIsShow}></i> */}
                {/* <button className="lp-btn mobile-hide">DOWNLOAD APP</button> */}
                <UserProfileModal status={profileModal} close={toggleProfileModal} user={user}/>
            </header>
        </>
    );
}

export default Header;
