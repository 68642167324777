import React from "react";
import { Col, Row } from "react-bootstrap";
import imageUrl from "utils/imageUrl";

const PersonItem = ({data}) => {
  console.log(data,"abc");

const diffrenceDate=(date)=>{
  const date1 = new Date();
  const date2 = new Date(date);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  return (diffDays + " days")
}
  return (
    <>
      <div className="person-item-wrapper">
        <div className="person-item">
          <Row>
            <Col className="col-3">
              <div className="rounded-square-img">
                <img src={imageUrl("placeholder.png")} alt="" />
              </div>
            </Col>
            <Col className="col-9">
              <p className="person-item-desc">
              {data?.userId?.name} invested <span className="fw-bold">${data?.amountInvested}</span> in{" "}
                <span className="fw-bold">{data?.storeId?.title}</span>
              </p>
              <p className="person-item-days">{diffrenceDate(data?.purchasedAt)} ago</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PersonItem;
