import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillInstagram,
  AiFillTwitterSquare,
} from "react-icons/ai";
import imageUrl from "utils/imageUrl";
import "../assets/css/footer.css";

const Footer = () => {
  return (
    <>
      <div className="custom-footer pt-5">
        <Container className="pb-5">
          <Row>
            <Col md={3}>
              <div className="footer-fr">
                <p className="h5 footer-heading">CONTACT US</p>
                <p className="footer-desc ">
                  Contact Us Java Times Caffé values professionalism and fun
                  both and always loves to be there in the service of its
                  customers!
                </p>
                <ul>
                  <li>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    <span>Av. Zarco # 532 ote</span>
                  </li>
                  <li>
                    <i className="fa fa-phone" aria-hidden="true"></i>

                    <span>(871) 6 88 38 75</span>
                  </li>
                  <li>
                    <i class="fa fa-envelope" aria-hidden="true"></i>
                    <span>johndoe@gmail.com</span>
                  </li>
                </ul>
                <img src={imageUrl("logo.webp")} alt="" className="pt-1" />
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-fr">
                <div className="footer-desc pb-5">
                  <p className="h5 footer-heading">OUR MISSION</p>
                  <p className="footer-desc">
                    To be the leading franchise company in the specialty coffee
                    industry, providing products of the best quality, the best
                    customer service, the best opportunities to our employees
                    and the best demonstration to one customer at a time.
                  </p>
                  <p className="h5 footer-heading">OUR VISION</p>
                  <p className="footer-desc">
                    We have the goal of bringing ease to every Mexican. This is
                    a simple way for all age groups to make money. with an eye
                    on creating AI systems in the future.
                  </p>
                </div>
                {/* <div className="footer-m-desc">
                  <p className="h5  footer-heading">Footer Title Two</p>
                  <p className="">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy{" "}
                  </p>
                </div> */}
              </div>
            </Col>
            <Col md={5}>
              <div className="footer-fr">
                <p className="h5  footer-heading">NEWSLETTER</p>
                <p className="footer-desc ">Subscribe to our newsletter</p>
                <div className="rounded-input-group row my-4">
                  <input
                    type="text"
                    placeholder="your@gmail.com"
                    className="rounded-input col-7"
                  />
                  <button className="lp-btn col-5">Get Started</button>
                </div>
                <Row>
                  <Col sm={6}>
                    <p className="h5 footer-heading">POWERED BY</p>
                    <p className="footer-desc">Startup Networks Sapi de CV</p>
                    <div className="footer-img">
                      <img src={imageUrl("power-by.png")} alt="" />
                    </div>
                  </Col>
                  <Col sm={6}>
                    <p className="h5  footer-heading pb-2">FOLLOW US</p>
                    <div className="footer-icons">
                      <a className="me-3" href="#">
                        <AiFillFacebook />
                      </a>
                      <a className="me-3" href="#">
                        <AiFillTwitterSquare />
                      </a>
                      <a className="me-3" href="#">
                        <AiFillInstagram />
                      </a>
                      <a className="me-3" href="#">
                        <AiFillLinkedin />
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;

// import React, { useState } from "react";
// import { Fade, Flash } from "react-reveal/";
// import { Container } from "react-bootstrap";
// import useTranslation from "hooks/useTranslation";

// function Footer() {
//   const [email, setEmail] = useState("");
//   const { language, setLanguage, t } = useTranslation();

//   const onSubmitHandler = (e) => {
//     e.preventDefault();
//     setEmail("");
//   };

//   return (
//     <>
//         <div className="footer-wrapper">
//         <Container>
//           <footer className="red-bg">
//             <h3 className="text-white text-uppercase">
//               {t("titles.footer")}
//             </h3>
//             <div className="join-section">
//               <form onSubmit={onSubmitHandler}>
//                 <input
//                   id="custom"
//                   className="custom-input"
//                   placeholder={t("placeholders.email")}
//                   type="email"
//                   value={email}
//                   onChange={(e) => setEmail(e.target.value)}
//                   required
//                 />
//                 <button className="btn secondary-btn">
//                   <i class="fa-solid fa-arrow-right"></i>
//                 </button>
//               </form>
//             </div>
//             <div className="footer-social">
//               <h3 className="common-heading text-white fw-light">{t("labels.follow_us")}</h3>
//               <ul>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-facebook-f"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-twitter"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-instagram"></i>
//                   </a>
//                 </li>
//                 <li>
//                   <a href="#" target="_blank">
//                     <i className="fa-brands fa-linkedin-in"></i>
//                   </a>
//                 </li>
//               </ul>
//             </div>
//             <div className="last-section">
//               <h2 className="fw-light">{t("labels.main")}</h2>
//               <p>©2022 JAVA TIMES CAFÉ. {t("labels.rights")} </p>
//             </div>
//           </footer>
//         </Container>
//         </div>
//     </>
//   );
// }

// export default Footer;
