import { BlogCardMain, Footer, MainHeader } from "components";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import imageUrl from "utils/imageUrl";
import { blogs } from "./../dummyData";

const BlogPage = () => {
  return (
    <>
      <div className="header-section">
        <MainHeader />
      </div>
      <Container>
        <article className="blog-page py-5">
          <p className="blog-title">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. A porro
            nostrum rem rerum, fuga provident.
          </p>
          <p className="blog-desc">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem cumque
            odio nemo fuga minus accusantium, libero consequatur animi deleniti
            amet adipisci magni iste facere necessitatibus molestias ducimus
            ipsa reprehenderit eius.
          </p>
          <div className="blog-main-img">
            <img src={imageUrl("business.png")} alt="" />
          </div>
          <Row className="blog-main">
            <Col md={8}>
              <dl>
                <dt>Lorem ipsum dolor sit, amet consectetur adipisicing.</dt>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Fuga, consectetur?
                </dl>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Sequi tempora at ea eos praesentium.
                </dl>
                <dl>
                  Reiciendis neque, deleniti cumque soluta eveniet voluptas
                  laboriosam unde atque temporibus voluptate recusandae optio!
                </dl>
                <dl>
                  Labore in cumque ad maiores nihil quos commodi delectus
                  deleniti asperiores, blanditiis dolorem deserunt.
                </dl>
                <dl>
                  Molestias nostrum odit expedita quisquam ipsa est aut,
                  corrupti autem dolorum tempore iste neque.
                </dl>
                <dl>
                  Culpa ipsa vitae adipisci voluptates sapiente? Corporis velit
                  praesentium quae aspernatur blanditiis ipsum magni?
                </dl>
                <ul>
                  <li>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  </li>
                  <li>
                    Deleniti iusto iure nesciunt tenetur reiciendis sunt
                    explicabo!
                  </li>
                  <li>
                    Ad eveniet molestiae rem dolorem consectetur sequi
                    accusantium.
                  </li>
                </ul>
                <div className="section-img">
                  <img src={imageUrl("prb.jpg")} alt="" />
                </div>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Fuga, consectetur?
                </dl>
                <dl>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Sequi tempora at ea eos praesentium.
                </dl>
                <dl>
                  Reiciendis neque, deleniti cumque soluta eveniet voluptas
                  laboriosam unde atque temporibus voluptate recusandae optio!
                </dl>
                <dl>
                  Labore in cumque ad maiores nihil quos commodi delectus
                  deleniti asperiores, blanditiis dolorem deserunt.
                </dl>
                <dl>
                  Molestias nostrum odit expedita quisquam ipsa est aut,
                  corrupti autem dolorum tempore iste neque.
                </dl>
                <dt>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                </dt>
                <dl>
                  Culpa ipsa vitae adipisci voluptates sapiente? Corporis velit
                  praesentium quae aspernatur blanditiis ipsum magni?
                </dl>
              </dl>
            </Col>
            <Col md={4}>
              <dt>RECENT ARTICLES</dt>
              {blogs.slice(0, 3).map((blog) => {
                return (
                  <>
                    <BlogCardMain
                      title={blog.title}
                      desc={blog.desc}
                      time={blog.time}
                      author={blog.author}
                      img={blog.img}
                    />
                  </>
                );
              })}
            </Col>
          </Row>
        </article>
      </Container>
      <Footer />
    </>
  );
};

export default BlogPage;
