import useTranslation from 'hooks/useTranslation';
import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import imageUrl from 'utils/imageUrl';
import '../sidebar/sidebar.css'
import utils from "../../utils";
import {ADMIN_TYPES} from "../../assets/constants";

const AdminNewSidebar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const {t} = useTranslation();


    // const { user } = useSelector((store) => store.user);
    const admin = useSelector((store) => store.admin);
    return <>
        <div className={`sidebar custom-scroll ${isOpen ? 'open' : ''}`}>
            <div className="logo-details logo-icon primary-logo">
                <div className="sb-icon-wrapper">
                    <i className='bx bxl-c-plus-plus icon'></i>
                </div>
                <img src={imageUrl('logo-icon.png')} alt=""/>
            </div>
            <div className="logo-details">
                <div className="sb-icon-wrapper">
                    <i className='bx bxl-c-plus-plus icon'></i>
                </div>
                <img src={imageUrl('bars-icon.png')} alt="" id="btn"
                     onClick={() => setIsOpen(prevState => !prevState)}/>
            </div>
            <ul className="nav-list">

                {
                    admin?.adminUser ?
                        <>
                            <li>
                                <Link to={'/admin/dashboard'}>
                                    <div className="sb-icon-wrapper">
                                        <img src={imageUrl('dash_icon.png')} alt=""/>
                                    </div>
                                    <span className="links_name">{t("buttons.dashboard")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/admin/wallet'}>
                                    <div className="sb-icon-wrapper">
                                        <img src={imageUrl('wallet_icon.png')} alt=""/>
                                    </div>
                                    <span className="links_name">{t("buttons.wallet")}</span>
                                </Link>
                            </li>
                            {utils.checkAdminType() === ADMIN_TYPES.ADMIN
                                ? <>
                                    <li>
                                        <Link to={'/admin/users'}>
                                            <div className="sb-icon-wrapper">
                                                <img src={imageUrl('user_icon.png')} alt=""/>
                                            </div>
                                            <span className="links_name">{t("buttons.users")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/admin/stores'}>
                                            <div className="sb-icon-wrapper">
                                                <img src={imageUrl('store_icon.png')} alt=""/>
                                            </div>
                                            <span className="links_name">{t("buttons.stores")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/admin/dividend'}>
                                            <div className="sb-icon-wrapper">
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" fill={"white"}>
                                                    <path d="M11.025 21v-2.15q-1.325-.3-2.287-1.15-.963-.85-1.413-2.4l1.85-.75q.375 1.2 1.113 1.825.737.625 1.937.625 1.025 0 1.738-.462.712-.463.712-1.438 0-.875-.55-1.388-.55-.512-2.55-1.162-2.15-.675-2.95-1.612-.8-.938-.8-2.288 0-1.625 1.05-2.525 1.05-.9 2.15-1.025V3h2v2.1q1.25.2 2.063.912.812.713 1.187 1.738l-1.85.8q-.3-.8-.85-1.2-.55-.4-1.5-.4-1.1 0-1.675.488-.575.487-.575 1.212 0 .825.75 1.3.75.475 2.6 1 1.725.5 2.613 1.587.887 1.088.887 2.513 0 1.775-1.05 2.7-1.05.925-2.6 1.15V21Z"/>
                                                </svg>
                                                {/*<img src={imageUrl('store_icon.png')} alt=""/>*/}
                                            </div>
                                            <span className="links_name">{t("buttons.dividend")}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={'/admin/transactions'}>
                                            <div className="sb-icon-wrapper">
                                                <img src={imageUrl('transaction_icon.png')} alt=""/>
                                            </div>
                                            <span className="links_name">{t("buttons.transactions")}</span>
                                        </Link>
                                    </li>
                                </>
                                : <></>
                            }
                        </>
                        :
                        <>
                            <li>
                                <Link to={'/user/dashboard'}>
                                    <div className="sb-icon-wrapper">
                                        <img src={imageUrl('dash_icon.png')} alt=""/>
                                    </div>
                                    <span className="links_name">{t("buttons.dashboard")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/user/wallet'}>
                                    <div className="sb-icon-wrapper">
                                        <img src={imageUrl('wallet_icon.png')} alt=""/>
                                    </div>
                                    <span className="links_name">{t("buttons.wallet")}</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={'/invest'}>
                                    <div className="sb-icon-wrapper">
                                        <img src={imageUrl('dollar-icon.png')} alt=""/>
                                    </div>
                                    <span className="links_name">{t("buttons.invest_now")}</span>
                                </Link>
                            </li>
                        </>
                }

            </ul>
        </div>
    </>
}

export default AdminNewSidebar