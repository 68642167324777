import React from "react";
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";

const ProtectedRoute = ({isAdmin = false}) => {
    const {pathname} = useLocation()
    const {user} = useSelector((store) => store.user);
    const {adminUser} = useSelector((store) => store.admin)

    if (isAdmin)
        return adminUser ? <Outlet/> : <Navigate to={'/admin-login'} state={{from: pathname}}/>
    else {
        if (!user?.isVerified) {
            const id = "_123";
            toast.warn('The User is not verified...', {toastId: id})
            return <Navigate to={'/'}/>
        } else
            return user ? <Outlet/> : <Navigate to={'/login'} state={{from: pathname}}/>
    }

}

export default ProtectedRoute
