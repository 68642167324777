import React, {useEffect, useRef, useState} from "react";

import {EntityModal, InvestCard} from "../components";
import apis from "../services";
import {Box, CssBaseline, Divider, Drawer, Grid, List, ListItem, ListItemButton, ListItemText} from "@mui/material";
import {STATES} from "../assets/constants";
import {useLocation, useNavigate} from "react-router-dom";
import _ from 'lodash'
import SearchBar from "../components/SearchBar";
import InvestOptionsModal from "../components/modals/InvestOptionsModal";

function InvestNow() {

    const drawerWidth = 240;

    const navigate = useNavigate();
    const searchRef = useRef('');

    const searchParams = new URLSearchParams(useLocation().search);
    let query = Object.fromEntries(searchParams)
     console.log(query.state,"query")

    const [stores, setStores] = useState([]);
    const [store, setStore] = useState(null);
    const [search, setSearch] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    const [investmentModal, setInvestmentModal] = useState(false);

    const toggleModal = (id) => {
        const selectedStore = stores.find(store => store._id === id);
        setStore(selectedStore);
        setShowModal((prevState) => !prevState)
    };
    const toggleInvestmentModal = () => setInvestmentModal(prevState => !prevState);

    const fetchActiveStore = async () => {
        const response = await apis.getAllActiveStores();
        if (response.status === 200) {
            setStores(response.data.stores)
        }
    }

    const handleDrawerToggle = () => setMobileOpen(prevState => !prevState);

    const stateFiltration = (store) => {
        // console.log({hasState});
        if (query.hasOwnProperty('state')) {
            return query['state'].includes(store.location.state)
        } else {
            return store
        }
    }

    const searchFiltration = (stores) => {
        return Object.values(stores).some(store => {
            if (typeof store === "string")
                return store.toLocaleLowerCase().includes(search.toLocaleLowerCase());
            else
                return searchFiltration(store);
        })
    }

    const searchStoreHandler = () => {
        setSearch(searchRef.current.value);
        console.log(searchRef.current.value)
    }

    const investCardProps = {toggleModal, toggleInvestmentModal}

    const container = window !== undefined ? () => window.document.body : undefined;

    useEffect(() => {
        (async () => {
            try {
                await fetchActiveStore();
            } catch (e) {
                console.log('Error fetching Stores')
            }
        })()
    }, []);

    const renderStoreNotFound = <Grid item xs justifyContent='center'><h3 className="common-heading text-center pt-5">No Stores Founds</h3></Grid>
      
    const drawer = (
       <div>
            <Divider/>
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(`?`)}>
                        <ListItemText primary="All"/>
                    </ListItemButton>
                </ListItem>
                {STATES.map((state) => (
                    <ListItem key={state} disablePadding selected={state === query.state}>
                        <ListItemButton onClick={() => navigate(`?state=${state}`)}>
                            <ListItemText primary={state} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const renderStores = () => {
        if(stores.length === 0) return renderStoreNotFound

        const stateFiltrationResult =  _.filter(stores, stateFiltration);

        if(stateFiltrationResult.length === 0) return renderStoreNotFound

        const searchFiltrationResult = stateFiltrationResult.filter(searchFiltration)

        if(searchFiltrationResult.length === 0) return renderStoreNotFound

        return searchFiltrationResult.map((store, index) =>
            <InvestCard key={index} {...investCardProps} {...store}/>
        )

    }

    return (
        <>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <Box
                    component="nav"
                    sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                    aria-label="mailbox folders"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: {xs: 'block', sm: 'none'},
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        PaperProps={{
                            sx: {
                                backgroundColor: 'inherit',
                                zIndex: 1
                            }
                        }}
                        sx={{
                            display: {xs: 'none', sm: 'block'},
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                                position: 'relative',
                                overflowY: 'auto',
                                height: '70vh'
                            },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{flexGrow: 1, p: 3, width: {sm: `calc(100% - ${drawerWidth}px)`}}}
                >
                    <Grid container direction='column'>
                        <Grid item>
                            <h3 className="common-heading text-center">Invest Now</h3>
                        </Grid>
                        <Grid item>
                            <SearchBar searchRef={searchRef} handleSearch={searchStoreHandler}/>
                        </Grid>
                        <Grid item>
                            <Grid item container gap={2} justifyContent='space-evenly'> {renderStores()} </Grid>
                        </Grid>
                    </Grid>
                </Box>
                {store && <EntityModal status={showModal} toggleModal={toggleModal} {...store} getStores={fetchActiveStore}/>}
                <InvestOptionsModal toggleModal={toggleInvestmentModal} status={investmentModal}/>
            </Box>
        </>
    );
}

export default InvestNow;
